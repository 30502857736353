import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { ELEMENT_TYPES, Text, TEXT_TYPES, FONT_SIZE } from 'components/Text/Text';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from '../../theme';

export enum LAYOUT {
  ACROSS_3 = '3_across',
  ACROSS_6 = '6_across',
}

type LayoutProps = {
  layout: LAYOUT;
};

type TeamMemberProps = {
  image: {
    filename: string;
    alt: string;
  };
  name: {
    content: WithRichTextProps;
  };
  role: {
    content: WithRichTextProps;
  };
  bio: {
    content: WithRichTextProps;
  };
  _uuid: string;
};

type TeamBlockProps = {
  title: {
    content: WithRichTextProps;
  };
  layout: LAYOUT;
  team: TeamMemberProps[];
};

export const TeamBlock = ({ blok }: { blok: TeamBlockProps }) => {
  const { title, team, layout } = blok;
  return (
    <StyledSection>
      <Grid container={true} columns={1}>
        <Col start="1" end="7">
          <Text content={title.content} type={TEXT_TYPES.HEADING_3} element={ELEMENT_TYPES.H2} align="center" />
        </Col>
        <TeamMemberContainer layout={layout}>
          {team.map(({ image, name, role, bio, _uuid }) => (
            <TeamMember key={_uuid} layout={layout}>
              <StyledImage src={image.filename} alt={image.alt} layout={layout} />
              <Text
                content={name.content}
                element={ELEMENT_TYPES.P}
                type={layout === LAYOUT.ACROSS_3 ? TEXT_TYPES.HEADING_5 : TEXT_TYPES.HEADING_7}
                fontSize={layout === LAYOUT.ACROSS_3 && FONT_SIZE.FONT_SIZE_22}
                align="center"
              />
              <Text
                content={role.content}
                element={ELEMENT_TYPES.P}
                type={layout === LAYOUT.ACROSS_3 ? TEXT_TYPES.PARAGRAPH_6 : TEXT_TYPES.PARAGRAPH_7}
                align="center"
                color={layout === LAYOUT.ACROSS_3 ? '#9538CC' : ''}
              />
              {bio && (
                <Text
                  content={bio.content}
                  element={ELEMENT_TYPES.P}
                  type={layout === LAYOUT.ACROSS_3 ? TEXT_TYPES.PARAGRAPH_6 : TEXT_TYPES.PARAGRAPH_7}
                  align="center"
                />
              )}
            </TeamMember>
          ))}
        </TeamMemberContainer>
      </Grid>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  padding: 13rem 0;

  div {
    text-align: center;
  }
`;

const StyledImage = styled.img<LayoutProps>`
  border-radius: 50%;
  max-width: 80%;
  margin-bottom: ${({ layout }) => (layout === LAYOUT.ACROSS_3 ? '2rem' : '1.6rem')};
`;

const TeamMember = styled.div<LayoutProps>`
  padding: ${({ layout }) => (layout === LAYOUT.ACROSS_3 ? `2rem` : `1rem`)};
  flex-basis: ${({ layout }) => (layout === LAYOUT.ACROSS_3 ? `100%` : `calc(100% / 2)`)};

  ${mq('sm')} {
    flex-basis: ${({ layout }) => (layout === LAYOUT.ACROSS_3 ? `calc(100% / 3)` : `calc(100% / 6)`)};
  }

  p:nth-of-type(2) {
    margin-bottom: 1rem;
  }
`;

const TeamMemberContainer = styled.div<LayoutProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;

  ${mq('md')} {
    margin-top: 5rem;
  }
`;
