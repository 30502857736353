import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Button } from 'components';
import { mq } from '../../theme';
import { FloatingFooterProps } from 'components/Header/Header';
import { Text, ELEMENT_TYPES, TEXT_TYPES } from '../Text/Text';

type FloatingHeroFooterProps = {
  show: true | false;
  data: FloatingFooterProps;
};

export const FloatingHeroFooter = ({ show = false, data }: FloatingHeroFooterProps) => {
  const { button, text } = data;
  const displayVariants = {
    show: {
      display: 'block',
      opacity: 1,
    },
    hide: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  };

  return (
    <Floating variants={displayVariants} animate={show ? 'hide' : 'show'} transition={{ duration: 0.25 }}>
      <FloatingContent>
        <Text content={text.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_6} color="white" />
        <Button text={button[0].url} url={button[0].text} />
      </FloatingContent>
    </Floating>
  );
};

const Floating = styled(motion.div)`
  position: fixed;
  bottom: 8vw;
  width: 100%;
  z-index: 20;

  ${mq('sm')} {
    bottom: 2vw;
  }
`;

const FloatingContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  ${mq('sm')} {
    flex-direction: row;
  }

  ${mq('md')} {
    width: 80%;
  }

  p {
    margin-bottom: 1.6rem;

    ${mq('md')} {
      margin-bottom: 0;
      max-width: 60%;
    }
  }

  a {
    height: 100%;
    width: 100%;

    ${mq('md')} {
      flex-direction: row;
      width: auto;
    }
  }
`;
