import styled from 'styled-components';
import { Grid } from 'components/Grid/Grid';
import { ELEMENT_TYPES, Text } from 'components/Text/Text';
import { mq } from '../../theme';

export const GradientThreeColumnBlock = ({ blok }: { blok: any }) => {
  const { content, title } = blok;

  return (
    <Grid columns={1} container={true}>
      <StyledBlock>
        <Text content={title.content} element={ELEMENT_TYPES.H2} />
        <StyledWrapper>
          {content.map(({ text_row_1, text_row_2, _uid }) => {
            return (
              <StyledColumn key={_uid}>
                <Text content={text_row_1.content} element={ELEMENT_TYPES.P} bold={true} />
                <Text content={text_row_2.content} element={ELEMENT_TYPES.P} />
              </StyledColumn>
            );
          })}
        </StyledWrapper>
      </StyledBlock>
    </Grid>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6vw;

  ${mq('sm')} {
    margin-top: 3vw;
  }
`;

const StyledColumn = styled.div`
  padding: 0 3vw;

  ${mq('sm')} {
    width: calc(100% / 3);
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;

    ${mq('sm')} {
      margin-bottom: 0;
    }
  }

  p:first-of-type {
    margin-bottom: 1rem;
  }

  ${mq('sm')} {
    &:nth-of-type(2) {
      border-right: 1px solid white;
      border-left: 1px solid white;
    }
  }
`;

const StyledBlock = styled.section`
  background: ${({ theme }) => theme.color.gradient};
  color: white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  border-radius: 0.8rem;
  padding: 6vw 0;
  margin-bottom: 6vw;
`;
