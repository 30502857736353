import styled from 'styled-components';
import { Text, ELEMENT_TYPES, TEXT_TYPES } from 'components/Text/Text';
import { Grid } from 'components/Grid/Grid';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from 'theme';

export type SpecializationBlockProps = {
  background_image: {
    filename: string;
  };
  text: {
    content: WithRichTextProps;
  };
  subtext: {
    content: WithRichTextProps;
  };
  specializations: {
    content: WithRichTextProps;
  };
};

export const SpecializationBlock = ({ blok }: { blok: SpecializationBlockProps }) => {
  const { background_image, text, subtext, specializations } = blok;
  return (
    <StyledBlock backgroundImage={background_image.filename}>
      <Grid container={true} columns={1}>
        <Text content={text.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_3} bold={true} />
        <Text content={subtext.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.HEADING_5} />
        <ol>
          {specializations.content[0].content.map((specialization, i) => (
            <Text
              key={i}
              content={specialization.content}
              element={ELEMENT_TYPES.LI}
              type={TEXT_TYPES.PARAGRAPH_6}
              color="black"
            />
          ))}
        </ol>
      </Grid>
    </StyledBlock>
  );
};

const StyledBlock = styled.section<{ backgroundImage: string }>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  padding: 13rem 0;

  ${mq('sm')} {
    padding: 20rem 0 13rem;
  }

  ${mq('md')} {
    padding: 28rem 0 13rem;
  }

  ${mq('lg')} {
    padding: 34rem 0 13rem;
  }

  .dark {
    color: ${({ theme }) => theme.color.dark};
  }

  .primary {
    color: ${({ theme }) => theme.color.primary};
  }

  p:first-of-type {
    margin-bottom: 9.6rem;
  }

  ol {
    margin: 3.5rem 0 0 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    ${mq('sm')} {
      max-height: 9rem;
    }

    ${mq('md')} {
      max-height: 12rem;
    }

    li {
      counter-increment: list;
      list-style-type: none;
      position: relative;
      padding-left: 1.5rem;

      ${mq('sm')} {
        width: 50%;
      }

      ${mq('md')} {
        width: calc((100% / 3) + 1.5rem);
      }

      &::before {
        color: ${({ theme }) => theme.color.primary};
        content: '0' counter(list);
        left: -3rem;
        position: absolute;
        text-align: center;
        width: 2.6rem;
      }
    }
  }
`;
