import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Text, ELEMENT_TYPES, TEXT_TYPES } from 'components/Text/Text';
import { mq } from '../../theme';

type ActiveTabProps = {
  active: boolean;
};

type ImageProps = {
  alt: string;
  filename: string;
};

type QuoteProps = {
  content: any[];
};

type EyeBrowProps = {
  text: string;
};

type QuotesProps = {
  _uid: string;
  name: string;
  title: string;
  image: ImageProps;
  quote: QuoteProps;
};

export type AssetProps = {
  filename: string;
};

type QuoteGroupProps = {
  quotes: QuotesProps[];
  eyebrow?: EyeBrowProps[];
  quotationMarkAsset?: AssetProps;
  backgroundImage?: AssetProps;
};

type QuotePanelProps = {
  minHeight: number;
};

type QuoteContainerProps = {
  backgroundImage: string;
};

export const QuoteGroup = ({ blok }: { blok: QuoteGroupProps }) => {
  const { backgroundImage, quotes, eyebrow, quotationMarkAsset } = blok;
  const startingQuote = quotes.length > 1 ? 1 : 0;
  const [selected, setSelected] = useState(startingQuote);
  const tabList = useRef<HTMLDivElement>();
  const quoteLength = tabList?.current?.children.length;
  const panelList = useRef<HTMLDivElement>();

  const getMinPanelHeight = () => {
    let minHeight = 0;
    for (let i = 0; i < quoteLength; i++) {
      const childNode = panelList.current.childNodes[i] as Element;
      const panelHeight = childNode.clientHeight;

      if (panelHeight > minHeight) {
        minHeight = panelHeight;
      }
    }
    return minHeight;
  };

  const minPanelHeight = getMinPanelHeight();

  const handleKeyDown = (e) => {
    if (tabList) {
      // Left arrow
      if (e.keyCode === 37) {
        const prevQuote = selected === 0 ? quoteLength - 1 : selected - 1;
        setSelected(prevQuote);
        const childNode = tabList.current.childNodes[prevQuote] as HTMLButtonElement;
        childNode.focus();
        // Right arrow
      } else if (e.keyCode === 39) {
        const nextQuote = selected === quoteLength - 1 ? 0 : selected + 1;
        setSelected(nextQuote);
        const childNode = tabList.current.childNodes[nextQuote] as HTMLButtonElement;
        childNode.focus();
      }
    }
  };

  return (
    <QuoteGroupContainer backgroundImage={backgroundImage?.filename || ''}>
      <QuoteGroupWrapper>
        {eyebrow[0] && <EyeBrowContainer>{eyebrow[0].text}</EyeBrowContainer>}
        <TabContainer role="tablist" aria-label="Sample Tabs" ref={tabList}>
          {quotes.map((quoteBlock, i) => {
            return (
              <QuoteTabButton
                key={quoteBlock._uid}
                role="tab"
                aria-controls={`panel-${i}`}
                id={`tab-${i}`}
                tabIndex={selected === i ? 0 : -1}
                onClick={() => setSelected(i)}
                onKeyDown={handleKeyDown}
                active={selected === i}
                aria-selected={selected === i}
              >
                <QuoteImage active={selected === i} src={quoteBlock.image.filename} alt={quoteBlock.image.alt || ''} />
                <p>{quoteBlock.name}</p>
                <p>{quoteBlock.title}</p>
              </QuoteTabButton>
            );
          })}
        </TabContainer>
        <QuotePanels minHeight={minPanelHeight} ref={panelList}>
          {quotationMarkAsset && <img src={quotationMarkAsset.filename} alt="" />}
          {quotes.map((quoteBlock, i) => {
            return (
              <QuotePanel
                key={i}
                aria-hidden={selected !== i}
                id={`panel-${i}`}
                role="tabpanel"
                tab-index="0"
                aria-labelledby={`tab-${i}`}
                active={selected === i}
              >
                <blockquote>
                  <Text content={quoteBlock.quote.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.HEADING_4} />
                </blockquote>
              </QuotePanel>
            );
          })}
          {quotationMarkAsset && <img src={quotationMarkAsset.filename} alt="" />}
        </QuotePanels>
      </QuoteGroupWrapper>
    </QuoteGroupContainer>
  );
};

const QuoteImage = styled.img<ActiveTabProps>`
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 70%;
  transform: scale(${({ active }) => (active ? '1' : '0.7')});
  transition: all ease-in 150ms;
  margin-bottom: 10px;
  box-shadow: ${({ active, theme }) => (active ? `0 0 0 0.8rem white, 0 0 0 1.2rem ${theme.color.primary}` : 'none')};
`;

const QuotePanels = styled.div<QuotePanelProps>`
  margin-top: 4.2rem;
  min-height: ${({ minHeight }) => `${minHeight}px`};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  img {
    width: 7%;

    &:nth-of-type(2) {
      transform: scaleX(-1) scaleY(-1);
    }
  }
`;

const QuoteGroupContainer = styled.section<QuoteContainerProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 6rem 0 14rem;

  ${mq('sm')} {
    padding: 6rem 0 18rem;
  }

  ${mq('md')} {
    padding: 13rem 0 30rem;
  }
`;

const QuoteGroupWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const QuoteTabButton = styled.button<ActiveTabProps>`
  appearance: none;
  background-color: transparent;
  transition: all ease-in 150ms;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  border: none;
  box-shadow: ${({ active, theme }) =>
    active ? `-1px 0 0px 0px ${theme.color.gray}, 1px 0 0px 0px ${theme.color.gray}` : 'none'};

  ${mq('md')} {
    padding: 0 3.8rem;
  }

  &:hover {
    cursor: pointer;
  }

  & > p {
    &:first-of-type {
      font-weight: bold;
      margin: 0 0 0.5rem 0;

      ${mq('md')} {
        font-size: 2.4rem;
      }
    }

    &:nth-of-type(2) {
      color: ${({ theme }) => theme.color.primary};
      font-weight: normal;
      font-size: 1.2rem;
      margin: 0;

      ${mq('md')} {
        font-size: 1.4rem;
      }
    }
  }
`;

const QuotePanel = styled.div<ActiveTabProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  & > blockquote {
    opacity: ${({ active }) => (active ? '1' : '0')};
    transition: opacity 0.5s ease-in-out;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
  }
`;

const EyeBrowContainer = styled.p`
  color: ${({ theme }) => theme.color.primary};
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 6.8rem;
  text-align: center;
  letter-spacing: 0.32rem;
`;

const TabContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
`;
