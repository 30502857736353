/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled, { css } from 'styled-components';
import { Grid, Col } from '../Grid/Grid';
import { Logo } from '../Logo/Logo';
import { iconMap } from '../Icons';

import { mediaQueries } from '../../theme';

type ColumnProps = {
  _uid: string;
  label: string;
  links: LinkProps[];
};

type LinkProps = {
  _uid: string;
  new_tab: boolean;
  link_url: LinkUrlProps;
  component: string;
  link_text: string;
};

type LinkUrlProps = {
  id: string;
  url: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
};

type SocialProps = {
  _uid: string;
  link: LinkProps[];
  platform: string;
  component: string;
};

type FooterProps = {
  data: {
    _uid: string;
    description: string;
    columns: ColumnProps[];
    secondary_nav: LinkProps[];
    socials: SocialProps[];
  };
};

export const Footer: React.FC<FooterProps> = ({ data }: FooterProps) => {
  const { columns, description, secondary_nav, socials } = data;

  return (
    <FooterContainer>
      <FooterInner>
        <Grid>
          <Col start="span 12" mdStart="span 4">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            {description && <FooterDescription>{description}</FooterDescription>}
          </Col>

          {columns && columns.length && (
            <Col start="1" mdStart="7" end="-1">
              <Grid columns={12}>
                {columns.map((column, i) => {
                  const { _uid, links } = column;
                  return (
                    <Col key={_uid} start="1" smStart={i * 4 + 1} end="span 12" smEnd="span 4">
                      {links && links.length && (
                        <FooterNavSubMenuItem>
                          {links.map((link, j) => {
                            const { _uid, link_url, link_text } = link;
                            if (j === 0) {
                              return (
                                <FooterNavLi key={_uid}>
                                  <FooterNavTitle>
                                    <a href={link_url.url}>{link_text}</a>
                                  </FooterNavTitle>
                                </FooterNavLi>
                              );
                            }
                            return (
                              <FooterNavLi key={_uid}>
                                <FooterNavLink href={link_url.url}>{link_text}</FooterNavLink>
                              </FooterNavLi>
                            );
                          })}
                        </FooterNavSubMenuItem>
                      )}
                    </Col>
                  );
                })}
              </Grid>
            </Col>
          )}
        </Grid>
      </FooterInner>
      <FooterBottom>
        <FooterInner>
          <Grid>
            <Col start="span 12" smStart="span 4">
              <FooterBottomText>&copy; 2001-2020 All Rights Reserved.</FooterBottomText>
            </Col>
            <Col start="span 12" smStart="span 8">
              <BottomNav>
                {secondary_nav && secondary_nav.length && (
                  <SecondaryNav>
                    {secondary_nav.map((item) => {
                      const { _uid, link_text, link_url } = item;
                      return (
                        <FooterNavLi key={_uid}>
                          <FooterSecondaryNavLink href={link_url.url}>{link_text}</FooterSecondaryNavLink>
                        </FooterNavLi>
                      );
                    })}
                  </SecondaryNav>
                )}
                {socials && socials.length && (
                  <SocialsNav>
                    {socials.map((item) => {
                      const { link, platform, _uid } = item;

                      const Icon = iconMap[platform];

                      if (Icon) {
                        return (
                          <li key={_uid}>
                            <a href={link[0].link_url.url} target="_blank" rel="noreferrer">
                              <Icon />
                              <span className="sr-only">{link[0].link_text}</span>
                            </a>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </SocialsNav>
                )}
              </BottomNav>
            </Col>
          </Grid>
        </FooterInner>
      </FooterBottom>
    </FooterContainer>
  );
};

Footer.displayName = 'Footer';

const BottomNav = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SocialsNav = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    max-width: 20px;
    max-height: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  li + li {
    margin-left: 10px;
  }

  a {
    color: ${(props) => props.theme.color.light};

    &:hover {
      color: ${(props) => props.theme.color.secondary};
    }
  }
`;

const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.color.dark};
  padding: 7rem 0 0;
  color: ${(props) => props.theme.color.gray};
  font-size: 1.4rem;
  letter-spacing: -0.2px;
`;

const FooterDescription = styled.p`
  margin-bottom: 4vmax;
`;

const FooterInner = styled.div`
  width: 85%;
  margin: 0 auto;

  ${mediaQueries('lg')`
      width: 80%;
    `}
`;

const FooterNavSubMenuItem = styled.ul`
  ${css`
    padding: 0;
    list-style-type: none;
    margin-bottom: 4vmax;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid ${(props) => props.theme.color.gray}60;
    padding-top: 2vmax;

    li {
      grid-column-start: 2;
    }

    li:first-child {
      grid-column-start: 1;
    }

    ${mediaQueries('sm')`
      display: block;
      border-top: 0;
      padding-top: 0;
    `}
  `}
`;

const FooterNavLink = styled.a`
  color: inherit;
  font-family: ${({ theme }) => theme.font.fallbackFont};
  line-height: 1.2;
  display: inline-block;
  text-decoration: none;
  font-weight: 300;

  &:hover {
    color: ${(props) => props.theme.color.secondary};
  }
`;

const FooterNavLi = styled.li`
  font-size: inherit;
  margin-bottom: 0.3rem;
`;

const FooterNavTitle = styled.h3`
  ${css`
    font-family: ${({ theme }) => theme.font.fallbackFont};
    color: ${(props) => props.theme.color.light};
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.375;
    letter-spacing: -0.2px;
    margin-bottom: 0;
    margin-top: 0;

    & > a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: ${(props) => props.theme.color.secondary};
      }
    }

    ${mediaQueries('sm')`
      margin-bottom: 0.6em;
    `}
  `}
`;

const FooterBottom = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.gray}60;
  padding: 3vmax 0;
  font-size: 1.2rem;
  margin-top: 4vmax;
`;

const FooterBottomText = styled.p`
  margin: 0;
`;

const SecondaryNav = styled.ul`
  ${css`
    list-style: none;
    color: ${(props) => props.theme.color.light};
    margin-top: 3vmax;
    width: 100%;
    display: flex;
    // justify-content: space-between;
    margin-top: 0;
    margin-right: 20px;
    align-items: center;

    li {
      margin-bottom: 0;
    }

    li + li {
      margin-left: 10px;
    }

    ${mediaQueries('sm')`
      justify-content: flex-end;
    `}
  `}
`;

const FooterSecondaryNavLink = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.color.secondary};
  }

  ${mediaQueries('md')`
    padding: 0 1rem;
  `}
`;

const LogoWrapper = styled.div`
  width: 150px;
`;
