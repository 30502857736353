import styled from 'styled-components';
import { Button } from 'components';
import { motion } from 'framer-motion';
import { CTAProps } from '../Header/Header';
import { MenuIcon } from '../Icons/MenuIcon';

type FloatingProps = {
  show: boolean;
  cta: CTAProps[];
  toggleMenu: any;
  open: boolean;
  forwardedRef: any;
};

type MotionProps = {
  variants: any;
};

export const FloatingHeader = ({ toggleMenu, show = false, cta, open, forwardedRef }: FloatingProps) => {
  const storedRegion = sessionStorage.getItem('region');
  const displayVariants = {
    show: {
      display: 'block',
      opacity: 1,
    },
    hide: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  };

  return (
    <Floating
      ref={forwardedRef}
      variants={displayVariants}
      animate={show ? 'show' : 'hide'}
      transition={{ duration: 0.25 }}
    >
      <Inner>
        {cta?.map((item, i) => {
          return (
            <Button
              key={i}
              size="md"
              url={`https://${storedRegion ? storedRegion : 'myodetox'}.janeapp.com`}
              text={item.text}
            />
          );
        })}
        <MenuButton
          id="off-canvas-toggle"
          onClick={toggleMenu}
          aria-controls="off-canvas-navigation"
          aria-expanded={open ? true : false}
        >
          <span className="sr-only">Menu</span>
          <MenuIcon open={open} />
        </MenuButton>
      </Inner>
    </Floating>
  );
};

const MenuButton = styled.button`
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: ${({ theme }) => theme.radius.button};
  border: 0;
  margin-left: 5px;
  padding: 0.5rem 3rem;
  transition: background-color 0.15s ease-in-out;

  svg {
    position: relative;
    top: 2px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.secondaryHover};
  }
`;

const Floating = styled(motion.div)<MotionProps>`
  position: fixed;
  top: 2vw;
  right: 2vw;
  z-index: 20;
  display: none;
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
`;
