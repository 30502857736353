import React, { useRef } from 'react';
import styled from 'styled-components';

import { FadeInSection } from 'components/FadeInSection/FadeInSection';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { ELEMENT_TYPES, TEXT_TYPES, Text } from 'components/Text/Text';
import { Button, ButtonProps } from 'components/Button/Button';

interface ContentProps {
  alignment: 'left' | 'right';
}

interface DivProps extends ContentProps {
  backgroundImage: string;
  order: number;
}

interface TreatmentBlok extends ContentProps {
  image: {
    filename: string;
  };
  text: { content: WithRichTextProps };
  link: ButtonProps[];
}

interface FadeInBackgroundContentBlockProps {
  blok: {
    treatment_blok: TreatmentBlok[];
  };
}

export const FadeInBackgroundContentBlock = ({ blok }: FadeInBackgroundContentBlockProps) => {
  const { treatment_blok } = blok;
  const stickyRef = useRef<HTMLDivElement>();
  const backgroundsLength = treatment_blok.length;

  return (
    <Wrapper>
      <StickyDiv ref={stickyRef}>
        {treatment_blok.map(({ image, alignment }, i) => (
          <Div alignment={alignment} order={backgroundsLength - i} backgroundImage={image.filename} key={i} />
        ))}
      </StickyDiv>
      {treatment_blok.map(({ text, link, alignment }, i) => (
        <FadeInSection position={i} stickyRef={stickyRef} backgroundsLength={backgroundsLength} key={i}>
          <ContentDiv alignment={alignment}>
            <ContentWrapper>
              <Text content={text.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_5} />
              <Button text={link[0].text} url={link[0].url} />
            </ContentWrapper>
          </ContentDiv>
        </FadeInSection>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const StickyDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: -2;
  margin-top: -100vh;
`;

const Div = styled.div<DivProps>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-color: ${({ theme }) => theme.color.lightGray};
  background-attachment: fixed;
  background-position: ${({ alignment }) => (alignment === 'left' ? 'bottom right' : 'bottom left')};
  background-repeat: no-repeat;
  background-size: contain;
  z-index: ${({ order }) => order};
`;

const ContentDiv = styled.div<ContentProps>`
  height: 100vh;
  display: flex;
  max-width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: ${({ alignment }) => (alignment === 'left' ? 'flex-start' : 'flex-end')};
`;

const ContentWrapper = styled.div`
  p {
    max-width: 450px;

    .dark {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  a {
    margin-top: 4rem;
  }
`;
