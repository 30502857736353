import { Placeholder } from '../Placeholder/Placeholder';
import { Button } from '../Button/Button';
import { CTA } from '../CTA/CTA';
import { Eyebrow } from '../Eyebrow/Eyebrow';

type BlockProps = {
  blok: any;
};

const Components = {
  cta: CTA,
  button: Button,
  eyebrow_text: Eyebrow,
};

export const DynamicContent = ({ blok }: BlockProps) => {
  if (blok) {
    if (typeof Components[blok.component] !== 'undefined') {
      const Component = Components[blok.component];
      return <Component {...blok} />;
    }
    return <Placeholder componentName={blok.component} />;
  }

  return null;
};
