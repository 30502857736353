import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { ELEMENT_TYPES, Text, TEXT_TYPES } from 'components/Text/Text';
import { mq } from '../../theme';
import { WithRichTextProps } from 'utils/hooks/withRichText';

export enum TITLE_ALIGNMENT {
  LEFT = 'left',
  RIGHT = 'right',
}

type TwoColumnContentBlockProps = {
  body: {
    _uid: string;
    text_row_1: {
      content: WithRichTextProps;
    };
  }[];
  title: {
    content: WithRichTextProps;
  };
  background_image: {
    filename: string;
  };
  background_color: {
    color: string;
  };
  title_alignment: TITLE_ALIGNMENT;
  overlay_preceeding_component: boolean;
};

export const TwoColumnContentBlock = ({ blok }: { blok: TwoColumnContentBlockProps }) => {
  const { body, title, background_image, background_color, title_alignment, overlay_preceeding_component } = blok;

  return (
    <StyledWrapper
      backgroundImage={background_image.filename}
      backgroundColor={background_color.color}
      addNegativeMargin={overlay_preceeding_component}
    >
      <Grid container={true} rows={12}>
        <Col start="1" end="13" smEnd="5" rowStart="4" smRowStart={title_alignment === 'right' ? '5' : '4'} rowEnd="13">
          {body.map(({ text_row_1, _uid }) => (
            <Text key={_uid} content={text_row_1.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_6} />
          ))}
        </Col>
        <Col
          start="1"
          end="13"
          smStart={title_alignment === 'right' ? '7' : '1'}
          smEnd={title_alignment === 'right' ? '13' : '9'}
          lgEnd={title_alignment === 'right' ? '13' : '7'}
          rowStart="1"
          rowEnd="4"
        >
          <Text content={title.content} type={TEXT_TYPES.HEADING_3} element={ELEMENT_TYPES.H2} />
        </Col>
      </Grid>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section<{ backgroundImage: string; backgroundColor: string; addNegativeMargin: boolean }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100%;
  padding: 13rem 0 20rem;

  ${({ addNegativeMargin }) =>
    addNegativeMargin &&
    `
    @media screen and (min-width: 600px) {
      margin-top: -200px;
    };

    @media screen and (min-width: 1400px) {
      margin-top: -350px;
    };
  `}

  ${mq('sm')} {
    padding: 13rem 0;
  }

  ${mq('md')} {
    background-size: 80%;
  }

  p {
    margin: 3rem 0;
  }

  .dark {
    color: ${({ theme }) => theme.color.dark};
  }

  .primary {
    color: ${({ theme }) => theme.color.primary};
  }
`;
