import styled from 'styled-components';
import { Grid, Col } from '../Grid/Grid';
import { mediaQueries } from '../../theme';
import SbEditable from 'storyblok-react';
import { InnerContents } from '../InnerContents/InnerContents';
import { ELEMENT_TYPES } from '../Text/Text';

type BlokProps = {
  blok: {
    _uid: string;
    component: string;
    image: {
      filename: string;
    };
    background_height: number;
    background_width: number;
    content: any;
  };
};

type BCImageBlockProps = {
  image: string;
  width: number;
  height: number;
};

export const BackgroundBoxContentBlock: React.FC<BlokProps> = ({ blok }: BlokProps) => {
  const { content, image, background_height, background_width } = blok;

  return (
    <SbEditable content={blok}>
      <Container>
        <BGImageBlock image={image.filename} width={background_width} height={background_height} />
        <Inner>
          <Grid rows={12}>
            <Col
              start="1"
              end="10"
              smEnd="7"
              mdStart="2"
              mdEnd="7"
              lgEnd="7"
              xlEnd="6"
              align="end"
              rowStart="8"
              rowEnd="13"
              xlRowStart="7"
            >
              <Box>
                <InnerContents content={content} element={ELEMENT_TYPES.H2} />
              </Box>
            </Col>
          </Grid>
        </Inner>
      </Container>
    </SbEditable>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  position: relative;
  height: 100vh;
`;

const Inner = styled.div`
  padding-bottom: 8vmax;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  position: relative;

  h2 {
    font-size: clamp(2rem, 1rem + 2vw, 4rem);
    line-height: 1;
    font-weight: 500;
    color: ${({ theme }) => theme.color.light};
  }

  h2 + a {
    margin-top: 1vmax;
  }

  strike {
    text-decoration: none;
    opacity: 0.7;
  }
`;

const BGImageBlock = styled.div<BCImageBlockProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ width }) => `${width}%`};
  height: ${({ height }) => `${height}%`};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const Box = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  background-image: ${({ theme }) => theme.color.gradient};
  padding: 6vmax;
  z-index: 1;

  h2 {
    font-size: clamp(2rem, 1rem + 2vw, 3rem);
  }

  h2 + a {
    margin-top: 4rem;
  }

  ${mediaQueries('md')`
        padding: 4vmax;
    `}
`;
