import styled from 'styled-components';
import { Grid, Col } from '../Grid/Grid';
import { InnerContents } from '../InnerContents/InnerContents';
import { ELEMENT_TYPES } from '../Text/Text';

type BlokProps = {
  blok: {
    _uid: string;
    component: string;
    image: {
      filename: string;
      alt: string;
    };
    content: any;
  };
};

export const TherapistBlock: React.FC<BlokProps> = ({ blok }: BlokProps) => {
  const { content, image } = blok;

  return (
    <Container>
      <BGImage src={image.filename} alt={image.alt} />
      <Inner>
        <Grid>
          <Col start="2" end="-2" mdStart="4" mdEnd="-4">
            <InnerContents content={content} element={ELEMENT_TYPES.H2} />
          </Col>
        </Grid>
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 80% 1fr;
`;

const Inner = styled.div`
  text-align: center;
  padding: 8vmax 0;
  width: 80%;
  margin: 0 auto;
  grid-column: 1 / -1;
  grid-row: 1 / -1;

  h2 {
    font-size: clamp(2rem, 1rem + 2vw, 4rem);
    line-height: 1;
  }

  h2 + a {
    justify-content: center;
  }

  h2 + button,
  h2 + a {
    margin-top: 2vmax;
  }
`;

const BGImage = styled.img`
  width: 100%;
  height: 90vmin;
  object-fit: cover;
  display: block;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
`;
