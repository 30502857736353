import styled from 'styled-components';
import { mq } from '../../theme';
import { withRichText, WithRichTextProps } from 'utils/hooks/withRichText';

/** TextTexts map out to values in design docs
https://www.figma.com/file/JWKXSPQbig69r6dLA9fuNi/Dev-Ready?node-id=880%3A2047
**/

export enum ELEMENT_TYPES {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  LI = 'li',
  OL = 'ol',
  P = 'p',
  SPAN = 'span',
  STRONG = 'strong',
  SUP = 'sup',
}

export type Align = 'left' | 'right' | 'center';

export enum TEXT_TYPES {
  HEADING_1 = 'heading1',
  HEADING_2 = 'heading2',
  HEADING_3 = 'heading3',
  HEADING_4 = 'heading4',
  HEADING_5 = 'heading5',
  HEADING_6 = 'heading6',
  HEADING_7 = 'heading7',
  PARAGRAPH_1 = 'paragraph1',
  PARAGRAPH_2 = 'paragraph2',
  PARAGRAPH_3 = 'paragraph3',
  PARAGRAPH_4 = 'paragraph4',
  PARAGRAPH_5 = 'paragraph5',
  PARAGRAPH_6 = 'paragraph6',
  PARAGRAPH_7 = 'paragraph7',
}

export enum FONT_SIZE {
  FONT_SIZE_127 = '12.7rem',
  FONT_SIZE_90 = '9rem',
  FONT_SIZE_64 = '6.4rem',
  FONT_SIZE_45 = '4.5rem',
  FONT_SIZE_31 = '3.1rem',
  FONT_SIZE_22 = '2.2rem',
  FONT_SIZE_16 = '1.6rem',
}

type StyledElementProps = {
  $align: Align;
  as?: React.ElementType;
  $bold: boolean;
  $color: string;
  $fontSize: string;
  $italic: boolean;
  $letterSpacing: string;
  $lineHeight: string;
  $strikethrough: boolean;
  $type: string;
  $underline: boolean;
  $uppercase: boolean;
};

const StyledElement = styled.span<StyledElementProps>`
  ${(props) => {
    const styles = Object.keys(props).map((prop) => {
      // Remove all falsy prop values
      if (!props[prop]) {
        return;
      }

      switch (prop) {
        case '$type':
          switch (props.$type) {
            case TEXT_TYPES.HEADING_1:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
                font-size: ${FONT_SIZE.FONT_SIZE_64};
                ${mq('sm')}{font-size: ${FONT_SIZE.FONT_SIZE_90};}
                ${mq('md')}{font-size: ${FONT_SIZE.FONT_SIZE_127};}
              `;
            case TEXT_TYPES.HEADING_2:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
  			        font-size: ${FONT_SIZE.FONT_SIZE_45};
  			        ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_64};}
  			        ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_90};}
  			      `;
            case TEXT_TYPES.HEADING_3:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
                font-size: ${FONT_SIZE.FONT_SIZE_45};
                ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_64}; }
  				    `;
            case TEXT_TYPES.HEADING_4:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
                font-size: ${FONT_SIZE.FONT_SIZE_31};
                ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_45}; }
  					`;
            case TEXT_TYPES.HEADING_5:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
                font-size: ${FONT_SIZE.FONT_SIZE_16};
  						  ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_22}; }
  						  ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_31}; }
  						`;
            case TEXT_TYPES.HEADING_6:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
  							font-size: ${FONT_SIZE.FONT_SIZE_16};
                ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_22}; }
              `;
            case TEXT_TYPES.HEADING_7:
              return `
                color: ${props.theme.color.dark};
                font-weight: bold;
  							font-size: ${FONT_SIZE.FONT_SIZE_16};
              `;
            case TEXT_TYPES.PARAGRAPH_1:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
                font-size: ${FONT_SIZE.FONT_SIZE_64};
                ${mq('sm')}{font-size: ${FONT_SIZE.FONT_SIZE_90};}
                ${mq('md')}{font-size: ${FONT_SIZE.FONT_SIZE_127};}
              `;
            case TEXT_TYPES.PARAGRAPH_2:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
  			        font-size: ${FONT_SIZE.FONT_SIZE_45};
  			        ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_64};}
  			        ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_90};}
  			      `;
            case TEXT_TYPES.PARAGRAPH_3:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
                font-size: ${FONT_SIZE.FONT_SIZE_31};
                ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_45}; }
                ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_64}; }
  				    `;
            case TEXT_TYPES.PARAGRAPH_4:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
                font-size: ${FONT_SIZE.FONT_SIZE_22};
                ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_31}; }
                ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_45}; }
  					`;
            case TEXT_TYPES.PARAGRAPH_5:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
                font-size: ${FONT_SIZE.FONT_SIZE_16};
  						  ${mq('sm')} { font-size: ${FONT_SIZE.FONT_SIZE_22}; }
  						  ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_31}; }
  						`;
            case TEXT_TYPES.PARAGRAPH_6:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
                line-height: 1.2;
  							font-size: ${FONT_SIZE.FONT_SIZE_16};
                ${mq('md')} { font-size: ${FONT_SIZE.FONT_SIZE_22}; }
              `;
            case TEXT_TYPES.PARAGRAPH_7:
              return `
                color: ${props.theme.color.gray};
                font-weight: normal;
                line-height: 1.2;
                font-size: ${FONT_SIZE.FONT_SIZE_16};
              `;
            default:
              break;
          }
        case '$align':
          return `text-align: ${props.$align};`;
        case '$bold':
          return `font-weight: bold;`;
        case '$italic':
          return `font-style: italic;`;
        case '$lineHeight':
          return `line-height: ${props.$lineHeight};`;
        case '$letterSpacing':
          return `letter-spacing: ${props.$letterSpacing};`;
        case '$strikethrough':
          return `text-decoration: line-through;`;
        case '$uppercase':
          return `text-transform: uppercase;`;
        case '$underline':
          return `text-decoration: underline;`;
        case '$color':
          return `color: ${props.$color};`;
        case '$fontSize':
          return `font-size: ${props.$fontSize};`;
        default:
          break;
      }
    });

    return `
      margin: 0;
      padding: 0;
      ${styles.join('')}
    `;
  }}
`;

interface TextProps {
  content: WithRichTextProps | string;
  element?: React.ElementType;
  type?: TEXT_TYPES;
  align?: Align;
  className?: string | [];
  color?: string;
  italic?: boolean;
  bold?: boolean;
  fontSize?: string;
  strikethrough?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  letterSpacing?: string;
  lineHeight?: string;
}

export const Text = ({
  align,
  bold = false,
  color,
  content,
  element,
  italic = false,
  lineHeight = '1',
  strikethrough = false,
  type,
  underline = false,
  uppercase = false,
  fontSize,
  letterSpacing,
  ...rest
}: TextProps) => {
  const Wrapper = ({ children }: { children: any }) => (
    <StyledElement
      as={element || ELEMENT_TYPES.SPAN}
      $align={align}
      $italic={italic}
      $lineHeight={lineHeight}
      $strikethrough={strikethrough}
      $type={type}
      $bold={bold}
      $underline={underline}
      $uppercase={uppercase}
      $color={color}
      $fontSize={fontSize}
      $letterSpacing={letterSpacing}
      {...rest}
    >
      {children}
    </StyledElement>
  );

  const richText =
    typeof content === 'string' ? (
      <Wrapper>{content}</Wrapper>
    ) : (
      content.map((textContent, i) => {
        const text = withRichText(textContent);
        if (!text) {
          return null;
        }

        return <Wrapper key={`${text}-${i}`}>{text}</Wrapper>;
      })
    );

  return <>{richText}</>;
};

Text.displayName = 'Text';
