import Storyblok from 'utils/storyblok';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { TherapistCard } from 'pages/therapists';

export const SpecialtyBlock = ({ blok }: { blok: any }) => {
  const { specialty: pageSpecialty } = blok;
  const specialty = pageSpecialty[0];
  const { sessionStorage } = window;
  const storedTherapists = sessionStorage.getItem('therapists');
  const [therapists, setTherapists] = useState([]);

  useEffect(() => {
    const fetchTherapists = async () => {
      const {
        data: { stories },
      } = await Storyblok.get(`cdn/stories`, {
        version: 'published',
        cv: Date.now(),
        resolve_relations: 'reference.reference',
        starts_with: `therapists`,
      });

      const filterTherapists = stories.filter((therapist) => therapist.content.specialty?.includes(specialty));
      setTherapists(filterTherapists);
    };

    if (!storedTherapists) {
      fetchTherapists();
    } else {
      const parseStoredTherapist = JSON.parse(storedTherapists);
      const filterTherapists = parseStoredTherapist.filter((therapist) =>
        therapist.content.specialty?.includes(specialty),
      );
      setTherapists(filterTherapists);
    }
  }, []);

  return (
    <StyledPosts>
      <Grid container={true}>
        {therapists.map((item) => {
          const { content, slug } = item;
          const { profile_pic, title, disciplines, gender, services, _uid } = content;
          const therapistServices = typeof services === 'string' ? null : services;
          return (
            <Col end="span 6" smEnd="span 6" mdEnd="span 4" key={_uid}>
              <TherapistCard
                profilePic={profile_pic}
                gender={gender}
                title={title}
                disciplines={disciplines}
                services={therapistServices}
                slug={slug}
              />
            </Col>
          );
        })}
      </Grid>
    </StyledPosts>
  );
};

const StyledPosts = styled.div`
  padding: 7vw 0;
`;
