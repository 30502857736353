import { DynamicBlock } from '../DynamicBlock/DynamicBlock';

type ReferenceProps = {
  blok: {
    reference: {
      content: any[];
    };
  };
};

export const Reference: React.FC<ReferenceProps> = ({ blok }: ReferenceProps) => {
  const { reference } = blok;

  if (reference.content) {
    return <DynamicBlock blok={reference.content} />;
  }

  return null;
};
