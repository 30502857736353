import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CTA } from 'components/CTA/CTA';
import { Text, ELEMENT_TYPES } from 'components/Text/Text';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from '../../theme';

type StyledHeroProps = {
  poster: string;
};

type TextBlokProps = {
  text_row_1: {
    content: WithRichTextProps;
  };
  text_row_2: {
    content: WithRichTextProps;
  };
  text_row_3: {
    content: WithRichTextProps;
  };
  text_row_4: {
    content: WithRichTextProps;
  };
};

type BackgroundVideoProps = {
  webm_video?: {
    filename: string;
  };
  mp4_video: {
    filename: string;
  };
  poster: {
    filename: string;
  };
};

type HeroProps = {
  text_blok: TextBlokProps[];
  background_video: BackgroundVideoProps[];
};

export const Hero = ({ blok }: { blok: HeroProps }) => {
  const { text_blok, background_video } = blok;

  const overlayRef = useRef<HTMLDivElement>();
  const heroContent = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleScrollEffect = (element) => {
      if (!element) {
        return null;
      }

      if (!heroContent?.current) {
        return;
      }

      const { childNodes } = heroContent.current;

      const distanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
      const elementHeight = element.offsetHeight;
      const scrollTop = document.documentElement.scrollTop;

      const scrollAmount = (scrollTop - distanceToTop) / elementHeight;

      const clipPath = Math.floor(scrollAmount * 150);
      childNodes.forEach((child) => {
        const myChild = child as HTMLHtmlElement;
        myChild.style.clipPath = `inset(0 0 ${clipPath}% 0)`;
      });

      let opacity = 0.3;

      if (scrollTop > distanceToTop) {
        opacity = 0.3 + scrollAmount;
      }

      if (opacity >= 0 && opacity <= 1) {
        element.style.opacity = opacity;
      }
    };
    const ref = overlayRef.current;
    window.addEventListener('scroll', () => handleScrollEffect(ref));

    return () => window.removeEventListener('scroll', () => handleScrollEffect(ref));
  }, []);

  const textBloks = Object.keys(text_blok[0]);

  return (
    <>
      <StyledHero poster={background_video[0].poster.filename}>
        <AnimatedOverlay />
        <VideoWrapper>
          <StyledVideo playsInline autoPlay muted loop poster={background_video[0].poster.filename}>
            {background_video[0].webm_video && (
              <source src={background_video[0].webm_video.filename} type="video/webm" />
            )}
            <source src={background_video[0].mp4_video.filename} type="video/mp4" />
          </StyledVideo>
          <Overlay ref={overlayRef} />
        </VideoWrapper>
        <HeroContent ref={heroContent}>
          {textBloks.map((text, i) => {
            if (text.includes('text_row')) {
              return (
                <Text
                  key={i}
                  content={text_blok[0][text].content}
                  element={ELEMENT_TYPES.H1}
                  uppercase={true}
                  letterSpacing="0.25vw"
                />
              );
            }
          })}
          <CTA url="#hero-bottom" />
        </HeroContent>
      </StyledHero>
      <div id="hero-bottom" />
    </>
  );
};

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-position: center center;
`;

const AnimatedOverlay = styled.div`
  @keyframes change-background-color {
    from {
      background-color: #000;
    }
    to {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }

  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100vh);
      transform: translateY(-100vh);
    }
  }

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 21;
  animation: change-background-color 0.2s ease-in alternate forwards,
    slide-out-top 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2s forwards;
`;

const HeroContent = styled.div`
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 23;
  width: 90%;

  ${mq('sm')} {
    width: 80%;
  }

  @keyframes slide-in-overlay-from-bottom {
    0% {
      top: 0;
      height: 100%;
    }
    100% {
      top: 100%;
      height: 0;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  h1 {
    font-size: clamp(2rem, 1rem + 9vw, 9vw);
    animation: slide-in-bottom 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1s forwards;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.color.primary};
      animation: change-background-color 0.2s ease-in alternate forwards,
        slide-in-overlay-from-bottom 1s ease 1s forwards;
    }

    &:nth-of-type(1),
    &:nth-of-type(4) {
      position: relative;
      right: 5%;
    }

    &:nth-of-type(2) {
      text-align: right;
    }

    &:nth-of-type(3) {
      text-align: left;
    }

    .secondary {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
`;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

const StyledHero = styled.div<StyledHeroProps>`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  color: white;

  a {
    position: absolute;
    opacity: 0;
    animation: fade-in 0.5s ease-in 2.5s forwards;
    transform: scale(2);
    bottom: 1rem;
    right: 0;

    ${mq('sm')} {
      transform: scale(2.5);
      bottom: 2.5rem;
    }

    ${mq('md')} {
      transform: scale(4);
      bottom: 5rem;
    }

    & > div:first-child {
      margin-left: 0;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    &:nth-child(1) {
      border: 1px solid pink;
    }

    svg {
      transform: rotate(0deg);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    video {
      display: none;
    }

    &::before {
      content: '';
      background-image: ${({ poster }) => `url(${poster})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
`;
