import styled from 'styled-components';

type BlockProps = {
  componentName: string;
};

export const Placeholder = ({ componentName }: BlockProps) => {
  return (
    <Container>
      <h2>{`Component ${componentName} hasn't been created yet.`}</h2>
    </Container>
  );
};

const Container = styled.div`
  background-color: rgba(255, 0, 0, 0.2);
  color: red;
`;
