import styled from 'styled-components';
import Link from 'next/link';
import { CTAProps, LinkProps } from '../Header/Header';
import { Button } from '../Button/Button';
import { motion } from 'framer-motion';
import { mq } from '../../theme';

type OffCanvasProps = {
  cta: CTAProps[];
  links: LinkProps[];
  secondaryNav: LinkProps[];
  toggleMenu: any;
  open: boolean;
  forwardedRef: any;
};

type OverlayProps = {
  variants: any;
};

const overlayVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

const offCanvasVariants = {
  show: {
    x: 0,
  },
  hide: {
    x: '-100%',
  },
};

const wrapperVariants = {
  show: {
    display: 'block',
    transition: {
      delayChildren: 0,
    },
  },
  hide: {
    display: 'none',
    transition: { when: 'afterChildren' },
  },
};

export const OffCanvasNav = ({
  open,
  toggleMenu,
  links = [],
  cta = [],
  secondaryNav = [],
  forwardedRef,
}: OffCanvasProps) => {
  return (
    <motion.div
      id="off-canvas-navigation"
      aria-labelledby="off-canvas-toggle"
      initial={false}
      variants={wrapperVariants}
      animate={open ? 'show' : 'hide'}
      aria-hidden={open ? false : true}
      ref={forwardedRef}
    >
      <Overlay onClick={toggleMenu} variants={overlayVariants}></Overlay>
      <OffCanvas variants={offCanvasVariants} transition={{ ease: 'easeIn' }}>
        {(links.length > 0 || cta.length > 0) && (
          <MainNav>
            <ul>
              {links.map(({ _uid, link_url, link_text }) => {
                return (
                  <li key={_uid}>
                    <NavLink href={link_url.cached_url}>{link_text}</NavLink>
                  </li>
                );
              })}
              {cta.length > 0 &&
                cta.map((item, i) => {
                  return (
                    <CTAItem key={i}>
                      <Button size="md" url={item.url} text={item.text} />
                    </CTAItem>
                  );
                })}
            </ul>
          </MainNav>
        )}
        {secondaryNav.length > 0 && (
          <SecondaryNav>
            <ul>
              {secondaryNav.map(({ _uid, link_url, link_text }) => {
                return (
                  <li key={_uid}>
                    <Link href={link_url.cached_url} locale={false} passHref={true}>
                      <SecondaryNavLink>{link_text}</SecondaryNavLink>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </SecondaryNav>
        )}
      </OffCanvas>
    </motion.div>
  );
};

const MainNav = styled.nav`
  margin-top: auto;
`;

const SecondaryNav = styled.nav`
  margin-top: auto;
`;

const CTAItem = styled.li`
  margin-top: 2rem;
`;

const SecondaryNavLink = styled.a`
  padding: 0.5rem 0;
  display: block;
  text-transform: uppercase;
  color: inherit;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;
  position: relative;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

const NavLink = styled.a`
  font-size: clamp(3rem, 1rem + 3vw, 5rem);
  color: ${({ theme }) => theme.color.light};
  text-decoration: none;
  font-weight: 700;
  line-height: 1;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

const Overlay = styled(motion.div)<OverlayProps>`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
`;

const OffCanvas = styled(motion.div)`
  width: 75%;
  background-color: ${({ theme }) => theme.color.dark};
  height: 100vh;
  color: ${({ theme }) => theme.color.light};
  padding: 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;

  ${mq('md')} {
    width: 50%;
  }
`;
