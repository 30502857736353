import React, { useEffect, useRef, useState } from 'react';

interface FadeInSectionProps {
  stickyRef: React.RefObject<HTMLDivElement>;
  position: number;
  backgroundsLength: number;
  children: any;
}

export const FadeInSection = ({ stickyRef, position, backgroundsLength, children }: FadeInSectionProps) => {
  const domRef = useRef();
  const [visible, setVisible] = useState(false);

  const background = stickyRef?.current?.children[position] as HTMLElement;
  const backgroundLength = backgroundsLength - 1;

  useEffect(() => {
    const handleScrollEffect = (node) => {
      if (!node) {
        return null;
      }

      const distanceToTop = window.pageYOffset + node.getBoundingClientRect().top;
      const elementHeight = node.offsetHeight;
      const scrollTop = document.documentElement.scrollTop;

      if (scrollTop > distanceToTop) {
        let opacity = 1;

        if (scrollTop > distanceToTop) {
          if (position >= backgroundLength && opacity >= 1) {
            opacity = 1;
          } else {
            opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
          }
        }

        if (opacity >= 0) {
          background.style.opacity = `${opacity}`;
        }
      }
    };

    const ref = domRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setVisible(entry.isIntersecting);
      });
    });
    observer.observe(domRef.current);

    if (visible) {
      window.addEventListener('scroll', () => handleScrollEffect(ref));
    }

    return () => {
      window.removeEventListener('scroll', () => handleScrollEffect(ref));
      observer.disconnect();
    };
  }, [visible, position]);

  return <div ref={domRef}>{children}</div>;
};
