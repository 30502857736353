import React from 'react';
import styled from 'styled-components';

import { Container } from '../Container/Container';

type BannerProps = {
  children: React.ReactNode;
  canDismiss?: boolean;
};

export const Banner: React.FC<BannerProps> = ({ children }: BannerProps) => {
  return (
    <BannerEl>
      <Container>
        <BannerText>{children}</BannerText>
      </Container>
    </BannerEl>
  );
};

const BannerEl = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  padding: 1.6rem 0;
`;

const BannerText = styled.p`
  ${({ theme }) => `
    color: ${theme.color.light};
    font-size: 1.2rem;
    font-family: ${theme.font.fallbackFont};

    ${theme.mq('lg')`
      font-size: 1.6rem;
      margin: 0;
    `}

    a {
      text-decoration: underline;
    }
  `}
`;
