import styled from 'styled-components';
import { Text, TEXT_TYPES, ELEMENT_TYPES } from 'components/Text/Text';
import { Grid, Col } from 'components/Grid/Grid';
import { mq } from '../../theme';
import { WithRichTextProps } from 'utils/hooks/withRichText';

type TextBlockProps = {
  textRow1: {
    content: WithRichTextProps;
  };
  textRow2: {
    content: WithRichTextProps;
  };
};

const TextBlock = ({ textRow1, textRow2 }: TextBlockProps) => (
  <StyledTextBlock>
    <Text content={textRow1.content} type={TEXT_TYPES.HEADING_6} element={ELEMENT_TYPES.P} align="center" />
    <Text content={textRow2.content} type={TEXT_TYPES.PARAGRAPH_7} element={ELEMENT_TYPES.P} align="center" />
  </StyledTextBlock>
);

const StyledTextBlock = styled.div`
  padding: 2rem;

  ${mq('sm')} {
    padding: 4rem 3rem;
  }

  ${mq('md')} {
    padding: 6.8rem 3rem;
  }

  p:nth-of-type(1) {
    margin-bottom: 1rem;
  }
`;

type ImageBlockProps = {
  primaryImage: {
    filename: string;
    alt: string;
  };
  secondaryImage: {
    filename: string;
    alt: string;
  };
};

const ImageBlock = ({ primaryImage, secondaryImage }: ImageBlockProps) => (
  <ImageBlockWrapper>
    <Grid>
      <Col start="1" end="10" rowStart="1">
        <img src={primaryImage.filename} alt={primaryImage.alt} />
      </Col>
      <Col start="7" end="13" rowStart="1" align="center" justify="center" height="auto">
        <img src={secondaryImage.filename} alt={secondaryImage.alt} />
      </Col>
    </Grid>
  </ImageBlockWrapper>
);

type ThreeColumnContentBlockProps = {
  title: {
    content: WithRichTextProps;
  };
  background_image: {
    filename: string;
  };
  content: {
    _uid: string;
    component: string;
    primary_image?: {
      filename: string;
      alt: string;
    };
    secondary_image?: {
      filename: string;
      alt: string;
    };
    text_row_1?: {
      content: WithRichTextProps;
    };
    text_row_2?: {
      content: WithRichTextProps;
    };
  }[];
};

export const ThreeColumnContentBlock = ({ blok }: { blok: ThreeColumnContentBlockProps }) => {
  const { content, title, background_image } = blok;
  return (
    <StyledWrapper backgroundImage={background_image.filename}>
      <Grid container={true} columns={1}>
        <Text content={title.content} type={TEXT_TYPES.HEADING_3} align="center" element={ELEMENT_TYPES.H2} />
        <StyledContent>
          {content.map((block) => {
            switch (block.component) {
              case 'text_blok':
                return <TextBlock key={block._uid} textRow1={block.text_row_1} textRow2={block.text_row_2} />;
              case 'double_image_block':
                return (
                  <ImageBlock
                    key={block._uid}
                    primaryImage={block.primary_image}
                    secondaryImage={block.secondary_image}
                  />
                );
              default:
                break;
            }
          })}
        </StyledContent>
      </Grid>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section<{ backgroundImage: string }>`
  padding: 13rem 0 3rem;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: contain;

  ${mq('sm')} {
    padding: 18rem 0 8rem;
  }

  ${mq('md')} {
    padding: 26rem 0 13rem;
  }

  h2 {
    margin-bottom: 3rem;

    ${mq('sm')} {
      margin-bottom: 6.5rem;
    }
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    flex-basis: calc(100% / 2);

    ${mq('md')} {
      flex-basis: calc(100% / 3);
      align-self: center;
    }
  }
`;

const ImageBlockWrapper = styled.div`
  order: 1;
  margin-top: 2rem;

  ${mq('md')} {
    order: 0;
    margin-top: 0;
  }
`;
