import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { Text, TEXT_TYPES, ELEMENT_TYPES } from 'components/Text/Text';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from '../../theme';

enum THEME {
  LEFT = 'left-align',
}

type ImageProps = {
  alt: string;
  filename: string;
};

type DoubleImageContentBlockProps = {
  theme: string;
  title: {
    content: WithRichTextProps;
  };
  portrait_image: ImageProps;
  landscape_image: ImageProps;
  above_image_text: {
    content: WithRichTextProps;
  };
  below_image_text: {
    content: WithRichTextProps;
  };
};

export const DoubleImageContentBlock = ({ blok }: { blok: DoubleImageContentBlockProps }) => {
  return (
    <Container>
      <Grid>
        <Col start="2" smStart="4" end="12">
          <Text content={blok.title.content} element={ELEMENT_TYPES.H2} />
          {blok.above_image_text.content && (
            <Text
              content={blok.above_image_text.content}
              element={ELEMENT_TYPES.P}
              type={TEXT_TYPES.PARAGRAPH_6}
              lineHeight="1.4"
            />
          )}
        </Col>
      </Grid>
      <Grid rows={12}>
        <Col
          start={blok.theme === THEME.LEFT ? '1' : '4'}
          end={blok.theme === THEME.LEFT ? '10' : '13'}
          rowStart="1"
          smRowStart="1"
          mdRowStart="1"
          lgRowStart="1"
          rowEnd="9"
        >
          <img src={blok.landscape_image.filename} alt={blok.landscape_image.alt} />
        </Col>
        <Col
          start={blok.theme === THEME.LEFT ? '8' : '1'}
          end={blok.theme === THEME.LEFT ? '13' : '6'}
          smStart={blok.theme === THEME.LEFT ? '8' : '2'}
          smEnd={blok.theme === THEME.LEFT ? '12' : '6'}
          rowStart="2"
          smRowStart="3"
          rowEnd="9"
          smRowEnd="12"
        >
          <img src={blok.portrait_image.filename} alt={blok.portrait_image.alt} />
        </Col>
        <Col
          start="2"
          end="12"
          smStart={blok.theme === THEME.LEFT ? '3' : '7'}
          smEnd={blok.theme === THEME.LEFT ? '7' : '11'}
          rowStart="10"
          rowEnd="13"
          align="end"
        >
          {blok.below_image_text.content && (
            <Text
              content={blok.below_image_text.content}
              element={ELEMENT_TYPES.P}
              type={TEXT_TYPES.PARAGRAPH_6}
              lineHeight="1.4"
            />
          )}
        </Col>
      </Grid>
    </Container>
  );
};

const Container = styled.section`
  margin: 7rem 0;

  ${mq('md')} {
    margin: 10rem 0;
  }

  h2,
  p {
    margin: 0;
  }

  h2 {
    & + p {
      margin-bottom: 3rem;

      ${mq('sm')} {
        max-width: 50%;
        margin-bottom: 6rem;
      }
    }
  }

  h2:last-of-type {
    margin-bottom: 3rem;

    ${mq('md')} {
      margin-bottom: 6rem;
    }
  }

  img {
    width: 100%;
  }
`;
