import styled from 'styled-components';
import { useLocale } from 'utils/hooks/useLocale';

const localeMap = {
  'en-CA': 'CA',
  'en-US': 'US',
};

export const LanguageToggle: React.FC = () => {
  const { locales, changeLocale, locale } = useLocale();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeLocale(e.target.value);
  };

  if (!locales) return null;

  return (
    <Toggle>
      <p className="sr-only">Choose Language</p>
      <select onChange={handleChange} onBlur={handleChange} value={locale}>
        {locales.map((loc) => (
          <option key={loc} value={loc}>
            {`${localeMap[loc]}`}
          </option>
        ))}
      </select>
    </Toggle>
  );
};

const Toggle = styled.div`
  padding-left: 1.5rem;

  select {
    appearance: none;
    background-color: transparent;
    color: inherit;
    font-size: 1.2rem;
    border: 0;
  }
`;
