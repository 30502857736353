export const LinkedIn = () => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
          <rect width="20" height="20" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M22.3352 18.7318C22.3856 18.7323 22.4355 18.7224 22.482 18.7028C22.5284 18.6832 22.5703 18.6543 22.6051 18.6178C22.6399 18.5813 22.6668 18.5381 22.6841 18.4908C22.7015 18.4435 22.709 18.3931 22.7061 18.3428C22.7061 18.0669 22.5396 17.9351 22.1982 17.9351H21.6464V19.3795H21.8539V18.7499H22.109L22.1148 18.7575L22.5105 19.3795H22.7324L22.3067 18.7358L22.3352 18.7318ZM22.0952 18.5871H21.8545V18.0988H22.1595C22.3171 18.0988 22.4967 18.1245 22.4967 18.3308C22.4967 18.5681 22.315 18.5871 22.0939 18.5871H22.0952ZM16.9219 16.9217H13.9792V12.3134C13.9792 11.2145 13.9596 9.79984 12.4487 9.79984C10.9161 9.79984 10.6816 10.9971 10.6816 12.2334V16.9214H7.73892V7.44478H10.5639V8.73986H10.6034C10.8861 8.25648 11.2947 7.85883 11.7855 7.58926C12.2763 7.31968 12.8311 7.18829 13.3907 7.20906C16.3732 7.20906 16.9231 9.17084 16.9231 11.723L16.9219 16.9217ZM4.41861 6.14939C4.08086 6.14945 3.75068 6.04936 3.46982 5.86177C3.18897 5.67418 2.97004 5.40751 2.84074 5.0955C2.71143 4.78349 2.67755 4.44014 2.74338 4.10887C2.80921 3.77761 2.9718 3.4733 3.21058 3.23443C3.44936 2.99557 3.75361 2.83288 4.08485 2.76692C4.41609 2.70097 4.75945 2.73473 5.07151 2.86392C5.38357 2.99312 5.65031 3.21195 5.838 3.49274C6.0257 3.77353 6.12591 4.10367 6.12597 4.44142C6.12601 4.66567 6.08188 4.88774 5.9961 5.09494C5.91032 5.30213 5.78456 5.49041 5.62602 5.64901C5.46748 5.80761 5.27925 5.93343 5.07208 6.01929C4.86491 6.10514 4.64286 6.14935 4.41861 6.14939V6.14939ZM5.88994 16.9217H2.9442V7.44478H5.88994V16.9217ZM18.3889 0.00135257H1.46551C1.0814 -0.00298215 0.711276 0.145358 0.436478 0.413776C0.16168 0.682194 0.0046879 1.04873 0 1.43284V18.4265C0.00452745 18.8108 0.161419 19.1775 0.436208 19.4462C0.710996 19.7149 1.08121 19.8636 1.46551 19.8595H18.3889C18.774 19.8643 19.1453 19.7161 19.4211 19.4474C19.697 19.1787 19.8549 18.8115 19.8603 18.4265V1.43161C19.8548 1.04674 19.6967 0.67979 19.4209 0.411379C19.145 0.142967 18.7738 -0.00494953 18.3889 0.000126457"
            fill="currentColor"
          />
        </g>
      </svg>
    </>
  );
};
