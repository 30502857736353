import React from 'react';
import styled from 'styled-components';

type ScreenReaderOnlyProps = {
  children: React.ReactNode;
};

export const ScreenReaderOnly: React.FC<ScreenReaderOnlyProps> = ({ children }: ScreenReaderOnlyProps) => {
  return <ScreenReaderOnlyContainer>{children}</ScreenReaderOnlyContainer>;
};

const ScreenReaderOnlyContainer = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;
