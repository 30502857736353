import styled from 'styled-components';
import { Text, ELEMENT_TYPES, TEXT_TYPES } from 'components/Text/Text';
import { Grid } from 'components/Grid/Grid';
import { WithRichTextProps } from 'utils/hooks/withRichText';

export const TextBlok = ({ blok }: { blok: { text_row_1: { content: WithRichTextProps } } }) => {
  return (
    <StyledContainer>
      <Grid container={true} columns={1}>
        <Text content={blok.text_row_1.content} type={TEXT_TYPES.HEADING_2} element={ELEMENT_TYPES.H1} />
      </Grid>
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  padding: 15rem 0 7rem;
`;
