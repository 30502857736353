import styled from 'styled-components';

type EyebrowProps = {
  text: string;
};

export const Eyebrow = ({ text }: EyebrowProps) => {
  return <Text>{text}</Text>;
};

const Text = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary};
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 1.5px;
  margin-bottom: 2vmax;

  &:first-child {
    margin-top: 0;
  }
`;
