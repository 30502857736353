import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Grid } from 'components/Grid/Grid';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { Text, TEXT_TYPES, ELEMENT_TYPES } from 'components/Text/Text';
import { CTA } from 'components/CTA/CTA';
import { LOCALE_MAP, getClosestLocation } from 'utils/hooks/useLocale';
import { mq } from '../../theme';

type ClinicProps = {
  phone: string;
  email: string;
  title: string;
  bookingUrl: string;
  backgroundImage: string;
  address: string;
  slug: string;
};

const Popup = ({ phone, email, title, backgroundImage, address, slug, bookingUrl }: ClinicProps) => (
  <StyledPopup>
    <a href={`/clinics/${slug}`}>
      <img src={backgroundImage} alt="Background of clinic." />
      <p>{title}</p>
      <p>{phone}</p>
      <p>{email}</p>
      <p>{address}</p>
    </a>
    <a href={bookingUrl} target="_blank" rel="noreferrer">
      Book now
    </a>
  </StyledPopup>
);

export const ClinicColumn = (props: ClinicProps) => (
  <StyledClinicColumn>
    <ClinicBody {...props} />
  </StyledClinicColumn>
);

const ClinicBody = ({ phone, email, title, backgroundImage, address, slug, bookingUrl }: ClinicProps) => (
  <>
    <a href={`/clinics/${slug}`}>
      <img src={backgroundImage} alt="Background of clinic." />
      <Text content={title} type={TEXT_TYPES.HEADING_6} element={ELEMENT_TYPES.P} />
      <Text content={phone} type={TEXT_TYPES.PARAGRAPH_7} element={ELEMENT_TYPES.P} />
      <Text content={email} type={TEXT_TYPES.PARAGRAPH_7} element={ELEMENT_TYPES.P} />
      <Text content={address} type={TEXT_TYPES.PARAGRAPH_7} element={ELEMENT_TYPES.P} />
    </a>
    <CTA url={bookingUrl} text="Learn more" theme="dark" />
  </>
);

const StyledPopup = styled.div`
  text-align: center;
  p {
    font-family: 'ReplicaStd', 'Helvetica Neue', sans-serif;
    display: block;
    margin: 0;
    padding: 0 2rem;
    color: #7f7f7f;
    font-size: 1.6rem;

    &:first-of-type {
      font-size: 2.2rem;
      color: black;
      font-weight: bold;
      margin: 1rem 0;
    }
  }

  a:last-of-type {
    font-size: 1.6rem;
    display: block;
    margin: 1rem 0 2rem;
  }
`;

const StyledMapBox = styled.div`
  height: 80vh;
`;

type ClinicBlockProps = {
  title: WithRichTextProps;
  clinic_cities: {
    _uid: string;
    latitude: string;
    longitude: string;
    region: string;
    jane_base_slug: string;
    country: string;
    clinics_in_city: {
      reference: {
        slug: string;
        content: {
          address: {
            city: string;
            country: string;
            province_or_state: string;
            street_address: string;
            zip_code_or_postal_code: string;
          }[];
          background_image: {
            filename: string;
          };
          hours_of_operation: {
            days: string;
            hours: string;
          }[];
          _uid: string;
          email: string;
          jane_base_slug: string;
          jane_clinic_slug: string;
          latitude: string;
          longitude: string;
          phone: string;
          title: string;
          slug: string;
        };
      };
    }[];
  }[];
};

export const ClinicBlock = ({ blok }: { blok: ClinicBlockProps }) => {
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));
  const { sessionStorage } = window;
  const locale = sessionStorage.getItem('preferredLocale');
  const storedRegion = sessionStorage.getItem('region');
  sessionStorage.setItem('clinics', JSON.stringify(blok.clinic_cities));
  const mapContainer = useRef();
  const map = useRef(null);
  const [region, setRegion] = useState('myodetox');

  const clinicBlockByCity = blok.clinic_cities.reduce(
    (acc, curr) => {
      const featuresByCity = curr.clinics_in_city.map(({ reference: { content: clinic, slug } }) => {
        if (!clinic) return;
        return {
          type: 'Feature',
          properties: {
            id: clinic._uid,
            phone: clinic.phone,
            email: clinic.email,
            title: clinic.title,
            bookingUrl: `https://${clinic.jane_base_slug}.janeapp.com/locations/myodetox-${clinic.jane_clinic_slug}/book`,
            backgroundImage: clinic.background_image.filename,
            address: `${clinic.address[0].street_address}, ${clinic.address[0].city}`,
            slug,
            region: curr.region,
          },
          geometry: {
            type: 'Point',
            coordinates: [Number(clinic.longitude), Number(clinic.latitude)],
          },
        };
      });

      return {
        ...acc,
        features: acc.features.concat(featuresByCity),
        [curr.jane_base_slug]: {
          latitude: curr.latitude,
          longitude: curr.longitude,
          features: featuresByCity,
          locale: curr.country,
        },
      };
    },
    {
      features: [],
    },
  );

  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiaGFsZXlib3dlcyIsImEiOiJja3N1bzE3ZGsxaTA2MnZwNzUxcnY2Z3dqIn0.11koj8-shHXu20NRTx_ePg';
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/haleybowes/ckt1o1ik30kzl18pxwq40746u',
      // Default center on Toronto
      center: [-79.381453, 43.740602],
      zoom: 10.5,
    });

    map.current.scrollZoom.disable();
    map.current.on('load', () => {
      // Load an image from an external URL.
      map.current.loadImage('/images/map-icon.png', (error, image) => {
        if (error) throw error;

        // Add the image to the map style.
        map.current.addImage('icon', image);
        map.current.addSource('locations', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: clinicBlockByCity.features,
          },
        });

        // Add a layer to use the image to represent the data.
        map.current.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'locations', // reference the data source
          layout: {
            'icon-image': 'icon', // reference the image
            'icon-size': 0.3,
            'icon-allow-overlap': true,
          },
        });

        map.current.on('click', (e) => {
          const mapFeatures = map.current.queryRenderedFeatures(e.point, {
            layers: ['points'],
          });

          if (mapFeatures.length > 0) {
            const feature = mapFeatures[0];
            const popupNode = document.createElement('div');

            const {
              properties: { address, backgroundImage, email, title, phone, bookingUrl, slug },
            } = feature;

            ReactDOM.render(
              <Popup
                phone={phone}
                address={address}
                backgroundImage={backgroundImage}
                email={email}
                title={title}
                bookingUrl={bookingUrl}
                slug={slug}
              />,
              popupNode,
            );
            popUpRef.current.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(map.current);

            map.current.flyTo({
              center: feature.geometry.coordinates,
            });
          }
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.current.on('mouseenter', 'points', () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });

        // // Change it back to a pointer when it leaves.
        map.current.on('mouseleave', 'points', () => {
          map.current.getCanvas().style.cursor = '';
        });
      });
    });

    return () => map.current.remove();
  }, []);

  useEffect(() => {
    if (clinicBlockByCity) {
      if (storedRegion) {
        setRegion(storedRegion);
        const closestLocation = clinicBlockByCity[storedRegion];
        map.current.jumpTo({ center: [Number(closestLocation.longitude), Number(closestLocation.latitude)] });
        return;
      }

      const clinicsInCountry = blok.clinic_cities.filter((clinic) => clinic.country === LOCALE_MAP[locale]);
      const fetchUserCoordinates = async () =>
        await axios
          .post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.NEXT_PUBLIC_LOCALE_KEY}`)
          .then(({ data }) => {
            const userCoords = { latitude: data.lat, longitude: data.lng };
            const closestClinic = getClosestLocation(userCoords, clinicsInCountry);
            sessionStorage.setItem('region', closestClinic.jane_base_slug);
            setRegion(closestClinic.jane_base_slug);
            map.current.jumpTo({ center: [Number(closestClinic.longitude), Number(closestClinic.latitude)] });
          })
          .catch(() => {
            throw new Error('Something went wrong.');
          });

      fetchUserCoordinates();
    }
  }, [storedRegion]);

  const regionMap = {
    myodetoxla: 'Los Angeles',
    myodetoxvancouver: 'Vancouver',
    myodetox: 'Toronto',
  };

  const handleCityChange = (e) => {
    sessionStorage.setItem('region', e.target.value);
    const selectedCity = clinicBlockByCity[e.target.value];
    setRegion(e.target.value);
    map.current.jumpTo({ center: [Number(selectedCity.longitude), Number(selectedCity.latitude)] });
  };

  return (
    <StyledSection>
      <StyledSectionHeader region={region}>
        <Text
          element={ELEMENT_TYPES.H2}
          type={TEXT_TYPES.HEADING_4}
          align="center"
          content="Find a clinic in"
          color="white"
        />
        <select value={region} onBlur={handleCityChange} onChange={handleCityChange}>
          {blok.clinic_cities.map(({ jane_base_slug, _uid }) => {
            return (
              <option key={_uid} value={jane_base_slug}>
                {regionMap[jane_base_slug]}
              </option>
            );
          })}
        </select>
      </StyledSectionHeader>
      <StyledMapBox ref={mapContainer} />
      <Grid container={true} columns={1}>
        <StyledClinicGrid>
          <Text
            element={ELEMENT_TYPES.H2}
            type={TEXT_TYPES.HEADING_4}
            align="center"
            content={`Clinics in ${regionMap[region]}`}
          />
          {clinicBlockByCity[region]?.features.map(
            ({ properties: { phone, email, title, bookingUrl, backgroundImage, address, slug, id } }) => (
              <ClinicColumn
                key={id}
                phone={phone}
                address={address}
                backgroundImage={backgroundImage}
                email={email}
                title={title}
                bookingUrl={bookingUrl}
                slug={slug}
              />
            ),
          )}
        </StyledClinicGrid>
      </Grid>
    </StyledSection>
  );
};

const StyledClinicGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 7vw 0;

  h2 {
    margin-bottom: 2rem;
    width: 100%;

    ${mq('md')} {
      margin-bottom: 5rem;
    }
  }
`;

const StyledClinicColumn = styled.div`
  width: calc(100% / 2 - 1rem);
  margin: 0 0.5rem 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq('md')} {
    flex-basis: calc(100% / 3 - 2rem);
    margin: 0 0.5rem 4rem;
  }

  img {
    margin-bottom: 1rem;
  }

  p:first-of-type {
    margin-bottom: 1rem;
  }
  a:last-of-type {
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
  }
`;

const StyledSectionHeader = styled.div<{ region: string }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: black;
  padding: 10rem 0 3vw;
  align-items: center;
  justify-content: center;

  ${mq('md')} {
    flex-direction: row;
  }

  select {
    font-size: 2.2rem;
    font-weight: bold;
    background: none;
    border: none;
    color: ${({ theme }) => theme.color.secondary};
    margin-left: 0.8rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/images/select-arrow.png');
    background-repeat: no-repeat;
    background-position: right;
    padding-right: ${({ region }) => (region === 'myodetoxla' || region === 'myodetoxvancouver' ? `4.6rem` : '0')};
    background-size: contain;

    ${mq('sm')} {
      font-size: 3.1rem;
      background-size: unset;
    }

    ${mq('md')} {
      font-size: 4.5rem;
    }
  }
`;

const StyledSection = styled.section`
  .mapboxgl-popup-content {
    padding: 0;
    font-family: 'ReplicaStd', 'Helvetica Neue', sans-serif;

    button {
      transform: scale(3);
      right: 1rem;
      top: 1rem;
    }

    a {
      text-decoration: none;
    }
  }
`;
