import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { ELEMENT_TYPES, Text, TEXT_TYPES } from 'components/Text/Text';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { CTA, ButtonProps } from 'components/CTA/CTA';
import { mq } from '../../theme';

type ImageProps = {
  alt: string;
  filename: string;
};

type SecondaryHeroProps = {
  title: {
    content: WithRichTextProps;
  };
  subtitle: {
    content: WithRichTextProps;
  };
  primary_image: ImageProps;
  secondary_image: ImageProps;
  call_to_action: ButtonProps[];
  background_image: ImageProps;
};

export const SecondaryHero = ({ blok }: { blok: SecondaryHeroProps }) => {
  const { call_to_action, title, subtitle, primary_image, secondary_image } = blok;
  return (
    <StyledHero backgroundImage={blok.background_image.filename}>
      <Grid rows={12} columns={10}>
        <Col rowStart="6" smRowStart="6" lgRowStart="6" start="1" end="6" lgStart="2" mdEnd="5">
          <Text content={title.content} element={ELEMENT_TYPES.H1} type={TEXT_TYPES.HEADING_3} />
          <Text content={subtitle.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_6} />
          <CTA text={call_to_action[0].text} url={call_to_action[0].url} theme={call_to_action[0].theme} />
        </Col>
        <Col justify="end" rowStart="1" rowEnd="7" start="7" end="11" lgStart="5">
          <ImageWrapper>
            <img src={secondary_image.filename} alt={secondary_image.filename} />
            <ImageOverlay />
          </ImageWrapper>
        </Col>
        <Col
          rowStart="6"
          smRowStart="6"
          lgRowStart="6"
          start="6"
          end="10"
          mdStart="5"
          mdEnd="8"
          lgStart="6"
          smEnd="9"
          lgEnd="10"
        >
          <img src={primary_image.filename} alt={primary_image.filename} />
        </Col>
      </Grid>
      <div id="learn-more" />
    </StyledHero>
  );
};

type StyledHeroProps = {
  backgroundImage: string;
};

const ImageWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(149, 56, 204, 0), #785cdd);
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledHero = styled.section<StyledHeroProps>`
  height: 70vh;

  ${mq('sm')} {
    height: 100vh;
  }
  position: relative;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  z-index: -2;

  h1 {
    margin: 0 0 5rem 0;

    ${mq('sm')} {
      margin: 0 0 6rem 0;
    }

    ${mq('md')} {
      margin: 0 0 8rem 0;
    }
  }

  a,
  p {
    line-height: 1.75rem;

    ${mq('md')} {
      line-height: 2.6rem;
      font-size: 2.2rem;
    }
  }

  h1,
  a,
  p {
    padding: 0 1rem 0 10%;

    ${mq('sm')} {
      padding: 0 3rem 0 10%;
    }

    ${mq('md')} {
      padding: 0 3rem 0 15%;
    }

    ${mq('lg')} {
      padding: 0 0 0 0;
    }
  }

  p {
    color: ${({ theme }) => theme.color.gray};
    margin-bottom: 1.6rem;
  }

  a div {
    transform: rotate(45deg);
  }
`;
