import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { Text, TEXT_TYPES, ELEMENT_TYPES } from 'components/Text/Text';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from '../../theme';

export enum LAYOUT {
  RIGHT_ALIGNED = 'right_aligned',
  LEFT_ALIGNED = 'left_aligned',
}

export enum PRIMARY_IMAGE_SIZE {
  LARGE = 'large',
  MEDIUM = 'medium',
}

type SecondaryDoubleImageContentBlockProps = {
  show_divider: string;
  primary_image: {
    filename: string;
    alt: string;
  };
  primary_image_size: PRIMARY_IMAGE_SIZE;
  secondary_image: {
    filename: string;
    alt: string;
  };
  heading: {
    content: WithRichTextProps;
  };
  body: {
    content: WithRichTextProps;
  };
  layout: LAYOUT;
};

export const SecondaryDoubleImageContentBlock = ({ blok }: { blok: SecondaryDoubleImageContentBlockProps }) => {
  const { show_divider, primary_image, heading, body, secondary_image, primary_image_size, layout } = blok;
  const primaryImageStart = {
    start: () => {
      if (layout === LAYOUT.LEFT_ALIGNED) {
        return 1;
      } else {
        if (primary_image_size === 'medium') {
          return 10;
        }
        return 8;
      }
    },
    end: () => {
      if (layout === LAYOUT.LEFT_ALIGNED) {
        if (primary_image_size === 'medium') {
          return 4;
        }
        return 6;
      } else {
        return 13;
      }
    },
  };

  const textStart = {
    start: () => {
      if (layout === LAYOUT.LEFT_ALIGNED) {
        if (primary_image_size === 'medium') {
          return 5;
        }
        return 7;
      }
      return 3;
    },
    end: () => {
      if (layout === LAYOUT.LEFT_ALIGNED) {
        return 11;
      }

      if (primary_image_size === 'medium') {
        return 9;
      }

      return 7;
    },
  };

  return (
    <Container showDivider={show_divider}>
      <Grid>
        <Col start={primaryImageStart.start()} end={primaryImageStart.end()}>
          <img src={primary_image.filename} alt={primary_image.alt} />
        </Col>
        <Col
          start={layout === LAYOUT.LEFT_ALIGNED ? textStart.start() : textStart.start() - 1}
          smStart={textStart.start()}
          end={layout === LAYOUT.LEFT_ALIGNED ? textStart.end() + 1 : textStart.end()}
          smEnd={textStart.end()}
          rowStart="1"
        >
          <Wrapper layout={layout}>
            <TextWrapper>
              {heading && <Text content={heading.content} type={TEXT_TYPES.HEADING_4} element={ELEMENT_TYPES.H2} />}
              <Text content={body.content} type={TEXT_TYPES.PARAGRAPH_5} element={ELEMENT_TYPES.P} />
            </TextWrapper>
            {secondary_image && <img src={secondary_image.filename} alt={secondary_image.alt} />}
          </Wrapper>
        </Col>
      </Grid>
    </Container>
  );
};

const TextWrapper = styled.div`
  .primary {
    color: ${({ theme }) => theme.color.primary};
  }

  .dark {
    color: ${({ theme }) => theme.color.dark};
  }

  h2 {
    margin-bottom: 4rem;
  }
`;

const Wrapper = styled.div<{ layout: LAYOUT }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  img {
    display: none;
    align-self: ${({ layout }) => (layout === LAYOUT.LEFT_ALIGNED ? 'flex-end' : 'flex-start')};
    justify-self: flex-end;
    width: 40%;
    max-width: 17.8rem;

    ${mq('lg')} {
      display: block;
    }
  }
`;

const Container = styled.section<{ showDivider: string }>`
  margin: 8rem 0;

  ${mq('sm')} {
    margin: 13rem 0;
  }

  img {
    width: 100%;
  }

  ${({ showDivider, theme }) =>
    showDivider === 'true' &&
    `h2::after {
    content: ' ';
    border: 0.2rem solid ${theme.color.primary};
    margin-top: 2rem;
    display: block;
    max-width: 4rem;

	${mq('sm')} {
    margin-top: 4rem;
  }
  }`}
`;
