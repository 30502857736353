import React from 'react';
import styled from 'styled-components';
import { breakpoints, mq } from '../../theme';

type GridProps = {
  children: React.ReactNode;
  columns?: number;
  rows?: number;
  container?: boolean;
};

type ContainerProps = {
  $columns?: number;
  $rows?: number;
  $container?: boolean;
};

type ColProps = {
  children: React.ReactNode;
  start?: number | string;
  xsStart?: number | string;
  smStart?: number | string;
  mdStart?: number | string;
  lgStart?: number | string;
  xlStart?: number | string;
  end?: number | string;
  xsEnd?: number | string;
  smEnd?: number | string;
  mdEnd?: number | string;
  lgEnd?: number | string;
  xlEnd?: number | string;
  align?: string;
  justify?: string;
  rowStart?: string;
  xsRowStart?: number | string;
  smRowStart?: number | string;
  mdRowStart?: number | string;
  lgRowStart?: number | string;
  xlRowStart?: number | string;
  rowEnd?: number | string;
  xsRowEnd?: number | string;
  smRowEnd?: number | string;
  mdRowEnd?: number | string;
  lgRowEnd?: number | string;
  xlRowEnd?: number | string;
  height?: string;
  alignContent?: string;
  className?: string;
};

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  grid-template-rows: ${({ $rows }) => ($rows ? `repeat(${$rows}, 1fr)` : 'auto')};
  margin-left: auto;
  margin-right: auto;
  width: ${({ $container }) => ($container ? '90%' : 'auto')};
  max-width: 2000px;
  height: 100%;

  ${mq('md')} {
    width: ${({ $container }) => ($container ? '85%' : 'auto')};
  }

  ${mq('lg')} {
    width: ${({ $container }) => ($container ? '80%' : 'auto')};
  }
`;

const Column = styled.div<{
  $start?: number | string;
  $xsStart?: number | string;
  $smStart?: number | string;
  $mdStart?: number | string;
  $lgStart?: number | string;
  $xlStart?: number | string;
  $end?: number | string;
  $xsEnd?: number | string;
  $smEnd?: number | string;
  $mdEnd?: number | string;
  $lgEnd?: number | string;
  $xlEnd?: number | string;
  $align?: string;
  $rowStart?: number | string;
  $xsRowStart?: number | string;
  $smRowStart?: number | string;
  $mdRowStart?: number | string;
  $lgRowStart?: number | string;
  $xlRowStart?: number | string;
  $rowEnd?: number | string;
  $xsRowEnd?: number | string;
  $smRowEnd?: number | string;
  $mdRowEnd?: number | string;
  $lgRowEnd?: number | string;
  $xlRowEnd?: number | string;
  $justify?: string;
  $height?: string;
  $alignContent?: string;
}>`
  ${(props) => {
    const mqs = Object.keys(breakpoints).map((item) => {
      const start = `$${item}Start`;
      const end = `$${item}End`;
      const rowStart = `$${item}RowStart`;
      const rowEnd = `$${item}RowEnd`;

      let mq = '';

      if (props[start] || props[end] || props[rowStart] || props[rowEnd]) {
        mq = `
            @media screen and (min-width: ${breakpoints[item]}px) {
                ${props[start] ? `grid-column-start: ${props[start]};` : ''}
                ${props[end] ? `grid-column-end: ${props[end]};` : ''}
                ${props[rowStart] ? `grid-row-start: ${props[rowStart]};` : ''}
                ${props[rowEnd] ? `grid-row-end: ${props[rowEnd]};` : ''}
            }`;
      }

      return mq;
    });

    return `
        grid-column-start: ${props.$start};
        grid-column-end: ${props.$end};
        align-self: ${props.$align ? props.$align : 'auto'};
        justify-self: ${props.$justify ? props.$justify : 'auto'};
        grid-row-start: ${props.$rowStart ? props.$rowStart : 'auto'};
        grid-row-end: ${props.$rowEnd ? props.$rowEnd : 'auto'};
        height: ${props.$height ? props.$height : '100%'};
        align-content: ${props.$alignContent ? props.$alignContent : 'auto'};
        ${mqs.join('')}
    `;
  }}
`;

export const Grid: React.FC<GridProps> = ({ children, columns = 12, rows, container = false }: GridProps) => (
  <Container $columns={columns} $rows={rows} $container={container}>
    {children}
  </Container>
);

export const Col: React.FC<ColProps> = ({
  children,
  start = 'auto',
  xsStart,
  smStart,
  mdStart,
  lgStart,
  xlStart,
  end = 'auto',
  xsEnd,
  smEnd,
  mdEnd,
  lgEnd,
  xlEnd,
  align,
  rowStart = 'auto',
  xsRowStart,
  smRowStart,
  mdRowStart,
  lgRowStart,
  xlRowStart,
  rowEnd = 'auto',
  xsRowEnd,
  smRowEnd,
  mdRowEnd,
  lgRowEnd,
  xlRowEnd,
  justify,
  height,
  alignContent,
  className,
}: ColProps) => (
  <Column
    $start={start}
    $xsStart={xsStart}
    $smStart={smStart}
    $mdStart={mdStart}
    $lgStart={lgStart}
    $xlStart={xlStart}
    $end={end}
    $xsEnd={xsEnd}
    $smEnd={smEnd}
    $mdEnd={mdEnd}
    $lgEnd={lgEnd}
    $xlEnd={xlEnd}
    $align={align}
    $justify={justify}
    $rowStart={rowStart}
    $xsRowStart={xsRowStart}
    $smRowStart={smRowStart}
    $mdRowStart={mdRowStart}
    $lgRowStart={lgRowStart}
    $xlRowStart={xlRowStart}
    $rowEnd={rowEnd}
    $xsRowEnd={xsRowEnd}
    $smRowEnd={smRowEnd}
    $mdRowEnd={mdRowEnd}
    $lgRowEnd={lgRowEnd}
    $xlRowEnd={xlRowEnd}
    $height={height}
    $alignContent={alignContent}
    className={className}
  >
    {children}
  </Column>
);
