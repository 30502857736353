import { useState, useEffect } from 'react';
import { Header, Footer, Head } from 'components';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Storyblok from 'utils/storyblok';
import { StoryData } from 'storyblok-js-client/types';
import styled from 'styled-components';
import { Grid, Col } from '../../components/Grid/Grid';
import Link from 'next/link';
import { ELEMENT_TYPES, Text, TEXT_TYPES } from 'components/Text/Text';
import { mq } from '../../theme';

type TherapistsProps = {
  story: StoryData;
  preview: boolean;
  navigation: {
    content: {
      header: any;
      footer: any;
    };
  };
  posts: any;
  locale: string;
  regions: any[];
  services: any[];
};

export const SERVICES_MAP = {
  physiotherapy: {
    'en-CA': 'Physiotherapy',
    'en-US': 'Physical Therapy',
  },
  chiropractic: {
    'en-CA': 'Chiropractic',
    'en-US': 'Chiropractic',
  },
  'massage-therapy': {
    'en-CA': 'Massage Therapy',
    'en-US': 'Massage Therapy',
  },
};

export const REGIONS_MAP = {
  toronto: 'Toronto',
  'los-angeles': 'Los Angeles',
  vancouver: 'Vancouver',
};

export const TherapistCard = ({ profilePic, gender, title, slug, locale, services }: any) => {
  return (
    <Link passHref href={`/therapists/${slug}`} locale={locale}>
      <StyledTherapistCard>
        {profilePic?.length > 0 ? (
          <img src={profilePic[0].filename} alt={profilePic[0].alt} />
        ) : (
          <img src={`/images/therapist_${gender}.jpeg`} alt="" />
        )}
        <TextOverlay>
          <Text content={title} element={ELEMENT_TYPES.P} type={TEXT_TYPES.HEADING_5} />
          {services?.map((service, index) => {
            return <Text key={index} content={service} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_7} />;
          })}
        </TextOverlay>
      </StyledTherapistCard>
    </Link>
  );
};

export const Therapists = ({ posts, locale, storedRegion = null, storedService = null, title }: any) => {
  const [therapistPool, setTherapistPool] = useState(posts);
  const [services, setServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(storedRegion);
  const [service, setService] = useState(storedService);

  const getRegionsAndServices = async () => {
    const {
      data: { datasource_entries: regions },
    } = await Storyblok.get(`cdn/datasource_entries?datasource=regions`);
    const {
      data: { datasource_entries: services },
    } = await Storyblok.get(`cdn/datasource_entries?datasource=services&dimension=${locale}`);

    setServices(services);
    setRegions(regions);
  };

  useEffect(() => {
    const filteredPool = posts.filter((pool) => {
      let therapist;

      const mappedService = service && SERVICES_MAP[service][locale];
      if (region && service) {
        if (region === pool.content.region && pool.content.services.includes(mappedService)) {
          return (therapist = pool);
        }
      }

      if (region && !service) {
        if (region === pool.content.region) {
          return (therapist = pool);
        }
      }

      if (service && !region) {
        if (pool.content.services.includes(mappedService)) {
          return (therapist = pool);
        }
      }

      if (!service && !region) {
        return (therapist = pool);
      }

      return therapist;
    });

    setTherapistPool(filteredPool);

    if (!services.length || !regions.length) {
      getRegionsAndServices();
    }
  }, [region, service, regions, services, storedService, storedRegion]);

  const handleSelectService = (e) => {
    setService(e.target.value);
  };

  const handleSelectRegion = (e) => {
    setRegion(e.target.value);
  };

  return (
    <StyledFilter>
      <Grid container={true} columns={1}>
        <ServiceSelector region={region} service={service}>
          <Text content={title} element={ELEMENT_TYPES.H1} type={TEXT_TYPES.HEADING_4} />
          {services?.length > 0 && (
            <>
              <select defaultValue={storedService} onChange={handleSelectService} onBlur={handleSelectService}>
                <option value="">therapists</option>
                {services.map((item) => {
                  const { id, name, value, dimension_value } = item;
                  return (
                    <option key={id} value={name}>
                      {dimension_value ?? value}
                    </option>
                  );
                })}
              </select>
              <Text content=" in " element={ELEMENT_TYPES.SPAN} type={TEXT_TYPES.HEADING_4} />
            </>
          )}
          {regions?.length > 0 && (
            <select defaultValue={storedRegion} onChange={handleSelectRegion} onBlur={handleSelectRegion}>
              <option value="">all cities</option>
              {regions.map((item) => {
                const { id, name, value } = item;
                return (
                  <option key={id} value={value}>
                    {name}
                  </option>
                );
              })}
            </select>
          )}
        </ServiceSelector>
      </Grid>
      <StyledPosts>
        {therapistPool?.length > 0 ? (
          <Grid container={true}>
            {therapistPool.map((item) => {
              const { content, slug } = item;
              const { profile_pic, title, disciplines, gender, services, _uid } = content;
              const therapistServices = typeof services === 'string' ? null : services;
              return (
                <Col end="span 6" smEnd="span 6" mdEnd="span 4" key={_uid}>
                  <TherapistCard
                    profilePic={profile_pic}
                    gender={gender}
                    title={title}
                    disciplines={disciplines}
                    services={therapistServices}
                    slug={slug}
                    locale={locale}
                  />
                </Col>
              );
            })}
          </Grid>
        ) : (
          <Grid container={true} columns={1}>
            <h2>No therapists found.</h2>
          </Grid>
        )}
      </StyledPosts>
    </StyledFilter>
  );
};

const StyledFilter = styled.section`
  background-image: url('/images/images-alt.png');
  background-size: contain;
  background-repeat: no-repeat;

  ${mq('sm')} {
    background-position: left -7rem;
  }
`;

function TherapistsPage({ navigation, posts, locale }: TherapistsProps) {
  const { t } = useTranslation('common');
  const { sessionStorage } = window;
  sessionStorage.setItem('therapists', JSON.stringify(posts));

  const {
    content: { footer, header },
  } = navigation;

  return (
    <>
      <Head title={t('title')} metadescription="View all Myodetox therapists" />
      <StyledMain>
        <Header data={{ ...header[0], show_floating_footer: false }} locale={locale} headerTheme="light" />
        <Therapists posts={posts} locale={locale} title="You are viewing all" />
        <Footer data={footer[0]} />
      </StyledMain>
    </>
  );
}

export async function getStaticProps({ locale = 'en-us' }) {
  const sbParams = {
    version: 'published',
    cv: Date.now(),
    resolve_relations: 'reference.reference',
  };

  const { data: navigation } = await Storyblok.get(`cdn/stories/${locale.toLowerCase()}/global`, sbParams);

  const { data: posts } = await Storyblok.get(`cdn/stories`, {
    ...sbParams,
    starts_with: `therapists`,
  });

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      navigation: navigation ? navigation.story : null,
      posts: posts.stories,
      locale,
    },
  };
}

export default TherapistsPage;

const StyledMain = styled.main``;

const StyledPosts = styled.div`
  padding: 7vw 0;
`;

const ServiceSelector = styled.section<{ region: string; service: any }>`
  text-align: center;
  padding-top: 15rem;

  h1 {
    margin-bottom: 0.5rem;
  }

  ${mq('sm')} {
    padding-top: 15vw;
  }

  select {
    font-weight: bold;
    font-size: 3.1rem;

    ${mq('md')} {
      font-size: 4.5rem;
    }

    border: none;
    border-bottom: ${({ theme }) => `2px dashed ${theme.color.lightGray}`};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/images/caret.png');
    background-repeat: no-repeat;
    background-position: right;
    background-color: transparent;

    &:first-of-type {
      width: ${({ service }) => (service ? 'auto' : '19rem')};
      padding-right: ${({ service }) => (service ? '4rem' : '0')};

      ${mq('md')} {
        width: ${({ service }) => (service ? 'auto' : '26rem')};
      }
    }

    &:last-of-type {
      width: ${({ region }) => (region ? 'auto' : '16rem')};
      padding-right: ${({ region }) => (region ? '4rem' : '0')};

      ${mq('md')} {
        width: ${({ region }) => (region ? 'auto' : '22rem')};
      }
    }
  }
`;

const StyledTherapistCard = styled.a`
  position: relative;
  display: block;
  padding-top: 8rem;
  margin: 0.1rem;
  background-color: #ececec;

  img {
    max-width: 100%;
    aspect-ratio: 1;
    display: block;
  }

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const TextOverlay = styled.div`
  padding: 2rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;
