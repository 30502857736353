import styled from 'styled-components';
import { CTA } from '../CTA/CTA';

type CardProps = {
  title: string;
  body: string;
  CTAText: string;
  CTALink: (...args: any[]) => any | string;
};

const Box = styled.div`
  padding: 4.6rem;
  background: ${({ theme }) => theme.color.gradient};
  max-width: 41rem;
`;

const CardTitle = styled.h3`
  color: ${({ theme }) => theme.color.light};
  margin: 0;
  font-family: ${({ theme }) => theme.font.header};
  text-transform: uppercase;
  opacity: 0.3;
  font-size: 1.2rem;
  letter-spacing: 0.2em;
  margin-bottom: 4rem;
`;

const CardText = styled.p`
  color: ${({ theme }) => theme.color.light};
  margin: 0;
  font-family: ${({ theme }) => theme.font.header};
  line-height: 1.2;
  font-size: 2.8rem;
  letter-spacing: -0.02em;
  margin-top: 0;
  margin-bottom: 4rem;
`;

export const Card: React.FC<CardProps> = ({ title, body, CTAText }: CardProps) => (
  <Box>
    <CardTitle>{title}</CardTitle>
    <CardText>{body}</CardText>
    {CTAText.length ? <CTA text={CTAText} /> : null}
  </Box>
);
