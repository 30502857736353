import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../theme';

type ContainerProps = {
  children: React.ReactNode;
  flex?: boolean;
};

export const Container: React.FC<ContainerProps> = ({ children, flex = false }: ContainerProps) => (
  <ContainerEl $flex={flex}>{children}</ContainerEl>
);

const ContainerEl = styled.div<{ $flex: boolean }>`
  ${({ $flex }) => `
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
    min-width: 100%;
    ${$flex ? 'display: flex; flex-wrap: wrap;' : 'display: block;'}

    ${mediaQueries('sm')`
      padding-left: 10vw;
      padding-right: 10vw;
    `}
  `}
`;
