export enum CONTENT_TYPES {
  TEXT = 'text',
  PARAGRAPH = 'paragraph',
  ORDERED_LIST = 'ordered_list',
  LIST_ITEM = 'list_item',
  HEADING = 'heading',
}

enum MARK_TYPES {
  BOLD = 'bold',
  ITALIC = 'italic',
  STYLED = 'styled',
  ORDERED_LIST = 'ordered_list',
}

export interface RichTextContentBlock {
  text?: string;
  type?: string;
  marks?: any[];
  content?: RichTextContentBlock[];
}

interface MarksProps {
  type: MARK_TYPES;
  attrs?: {
    class: string;
  };
}

export type NestedRichText = {
  type?: CONTENT_TYPES;
  content: RichTextContentBlock[];
};

export type WithRichTextProps = RichTextContentBlock[] | NestedRichText[];
export const withRichText = (content: NestedRichText) => {
  if (!content) {
    return null;
  }

  const richTextMarks = (mark: MarksProps, text: string) => {
    switch (mark.type) {
      case MARK_TYPES.BOLD:
        return <strong key={`${text}-strong`}>{text}</strong>;
      case MARK_TYPES.ITALIC:
        return <em key={`${text}-italic`}>{text}</em>;
      case MARK_TYPES.STYLED:
        return (
          <span className={mark.attrs.class} key={`${text}-span`}>
            {text}
          </span>
        );
      default:
        return text;
    }
  };

  const getMarks = (richTextContent) => {
    if (typeof richTextContent === 'object' && richTextContent.text) {
      if (richTextContent.marks) {
        return richTextMarks(richTextContent.marks[0], richTextContent.text);
      }
      return richTextContent.text;
    }

    if (!richTextContent.content) {
      return null;
    }

    return richTextContent.content.reduce((acc, curr) => {
      if (curr.marks) {
        const text = richTextMarks(curr.marks[0], curr.text);
        acc.push(text);
        return acc;
      }

      acc.push(curr.text);
      return acc;
    }, []);
  };

  switch (content.type) {
    case CONTENT_TYPES.PARAGRAPH:
    case CONTENT_TYPES.HEADING:
      return getMarks(content);
    default:
      return getMarks(content);
  }
};
