import styled from 'styled-components';
import { Grid, Col } from '../Grid/Grid';
import SbEditable from 'storyblok-react';
import { InnerContents } from '../InnerContents/InnerContents';
import { ELEMENT_TYPES } from '../Text/Text';

type BlokProps = {
  blok: {
    _uid: string;
    component: string;
    image: {
      filename: string;
      alt: string;
    };
    overlay_opacity?: string | number;
    content: any;
  };
};

type OverlayProps = {
  $overlay: string | number;
};

export const BackgroundContentBlock: React.FC<BlokProps> = ({ blok }: BlokProps) => {
  const { content, image, overlay_opacity: overlay } = blok;

  return (
    <SbEditable content={blok}>
      <Container>
        <BGImage src={image.filename} alt={image.alt} />
        <Overlay $overlay={overlay}></Overlay>
        <Inner>
          <Grid>
            <Col start="2" end="-2" smEnd="8" lgEnd="7" align="center" alignContent="center" height="auto">
              <InnerContents content={content} element={ELEMENT_TYPES.H2} />
            </Col>
          </Grid>
        </Inner>
      </Container>
    </SbEditable>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`;

const Inner = styled.div`
  padding: 8vmax 0;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  position: relative;

  h2 {
    font-size: clamp(2rem, 1rem + 2vw, 4rem);
    line-height: 1;
    font-weight: 500;
    color: ${({ theme }) => theme.color.light};
    display: inline;
  }

  h2 + a {
    margin-top: 2vmax;
    max-width: 40%;
  }

  strike {
    text-decoration: none;
    opacity: 0.7;
  }

  a {
    display: block;
  }

  .secondary {
    color: ${({ theme }) => theme.color.secondary};
  }

  .primary {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const BGImage = styled.img`
  width: 100%;
  height: 90vmin;
  display: block;
  object-fit: cover;
  object-position: center;
  min-height: 600px;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
`;

const Overlay = styled.div<OverlayProps>`
  background-image: linear-gradient(to bottom, black, black);
  opacity: ${({ $overlay }) => $overlay};
  width: 100%;
  height: 90vmin;
  min-height: 600px;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
`;
