export const Facebook = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9167 0H1.08329C0.499958 0 0 0.499998 0 1.08333V18.9167C0 19.5 0.499958 20 1.08329 20H10.6667V12.25H8.08332V9.25001H10.6667V6.99999C10.6667 4.41666 12.2501 3.00001 14.5834 3.00001C15.6667 3.00001 16.6667 3.08332 16.9167 3.08332V5.83335H15.3333C14.0833 5.83335 13.8334 6.41666 13.8334 7.33333V9.25001H16.8333L16.4167 12.25H13.8334V20H18.9167C19.5 20 20 19.5 20 18.9167V1.08333C20 0.499998 19.5 0 18.9167 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
