import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { ELEMENT_TYPES, Text, TEXT_TYPES } from 'components/Text/Text';
import { CTA, ButtonProps } from 'components/CTA/CTA';
import { WithRichTextProps } from 'utils/hooks/withRichText';

type TertiaryHeroProps = {
  cta: ButtonProps[];
  title: {
    content: WithRichTextProps;
  };
  subtitle: {
    content: WithRichTextProps;
  };
  background_image: {
    filename: string;
  };
};

export const TertiaryHero = ({ blok }: { blok: TertiaryHeroProps }) => {
  const { background_image, title, subtitle } = blok;
  return (
    <StyledContainer backgroundImage={background_image.filename}>
      <Grid container={true} rows={12}>
        <Col start="1" end="10" rowStart="3" lgEnd="8">
          <Text content={title.content} type={TEXT_TYPES.HEADING_3} element={ELEMENT_TYPES.H1} />
        </Col>
        <Col start="1" end="8" smEnd="5" mdStart="11" mdEnd="13" rowStart="5" mdRowStart="3">
          <Text content={subtitle.content} type={TEXT_TYPES.PARAGRAPH_7} element={ELEMENT_TYPES.P} />
          <CTA justify="right" url="#learn-more" />
        </Col>
      </Grid>
      <div id="learn-more" />
    </StyledContainer>
  );
};

const StyledContainer = styled.section<{ backgroundImage: string }>`
  padding-top: 9rem;
  height: 100vh;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;

  a div {
    border: none;

    div {
      transform: scale(2.5) rotate(90deg);
      background: ${({ theme }) => theme.color.gradient};
      border-radius: 50%;
      margin-top: 5rem;
      border: none;
      position: relative;
      left: 1.3rem;

      &:hover {
        background: ${({ theme }) => theme.color.secondary};
      }
    }
  }
`;
