import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { Grid, Col } from 'components/Grid/Grid';
import { Button } from 'components/Button/Button';
import { Text, TEXT_TYPES, ELEMENT_TYPES } from 'components/Text/Text';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from '../../theme';

type InputProps = {
  _uid: string;
  name?: string;
  label?: string;
  placeholder?: string;
  component: string;
};

type NewsletterSubscriptionBlockProps = {
  blok: {
    form: [
      {
        inputs: InputProps[];
      },
    ];
    text: {
      content: WithRichTextProps;
    };
  };
};

export const NewsletterSubscriptionBlock = ({ blok }: NewsletterSubscriptionBlockProps) => {
  const { form, text } = blok;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = () => {
    // Subscription service goes here.
  };

  const { inputs } = form[0];
  return (
    <Container>
      <Grid container={true}>
        <Col start="1" end="13" mdStart="1" mdEnd="5">
          <Text
            align="center"
            element={ELEMENT_TYPES.P}
            type={TEXT_TYPES.PARAGRAPH_5}
            content={text.content}
            color="white"
          />
        </Col>
        <Col align="center" start="1" end="13" mdStart="6" mdEnd="13">
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {inputs.map((input) => {
              switch (input.component) {
                case 'text_input':
                  return (
                    <FieldDiv key={input._uid}>
                      <input
                        placeholder={input.placeholder}
                        id={input.name}
                        type="email"
                        aria-describedby={`${input.name}-error`}
                        {...register(input.name, {
                          required: true,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Entered value does not match email format.',
                          },
                        })}
                      />
                      <label htmlFor={input.name}>{input.label}</label>
                      {errors[input.name] && <p id={`${input.name}-error`}>{errors[input.name].message}</p>}
                    </FieldDiv>
                  );
                case 'submit_input':
                  return <Button type="submit" key={input._uid} theme="light" text={input.label} />;

                default:
                  return null;
              }
            })}
          </StyledForm>
        </Col>
      </Grid>
    </Container>
  );
};

const Container = styled.section`
  background: ${({ theme }) => theme.color.gradient};
  padding: 10rem 0;

  p {
    margin-bottom: 2.2rem;

    .secondary {
      color: ${({ theme }) => theme.color.secondary};
    }

    ${mq('md')} {
      text-align: left;
      margin-bottom: 0;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  position: relative;

  div {
    flex-grow: 1;
  }

  button {
    border-radius: 0 0.4rem 0.4rem 0;
    cursor: pointer;
  }
`;

const FieldDiv = styled.div`
  display: flex;
  flex-flow: column-reverse;

  label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
    font-size: 1.6rem;
    color: white;
  }

  label {
    margin-top: -2.6rem;
  }

  label + p {
    position: absolute;
    font-size: 1.4rem;
    top: 7rem;
    color: white;

    ${mq('sm')} {
      top: 5rem;
    }
  }

  input {
    border: 0;
    -webkit-appearance: none;
    border-radius: 0.4rem 0 0 0.4rem;
    padding: 1.4rem;
    cursor: text;
    background: rgba(255, 255, 255, 0.2);
    line-height: 1;
    height: 100%;

    &:focus {
      outline: 0;
    }

    &:placeholder-shown + label {
      cursor: text;
      transform-origin: left bottom;
      transform: translate(1.5rem, 145%);
    }

    &::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }

    &:focus::-webkit-input-placeholder {
      opacity: 1;
      color: white;
    }

    &:not(:placeholder-shown) + label,
    &:focus + label {
      font-size: 1.4rem;
      transform: translate(0, 0);
      cursor: pointer;
    }
  }
`;
