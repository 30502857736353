/* eslint-disable react/no-unescaped-entities */
import NextHead from 'next/head';
import { useRouter } from 'next/router';

interface Props {
  children?: JSX.Element[] | JSX.Element;
  title: string;
  metadescription: string;
}

export const Head: React.FC<Props> = ({ children, title, metadescription }: Props) => {
  const { locale, locales, pathname } = useRouter();

  const titleVal = title.length ? `Myodetox - ${title}` : 'Myodetox';

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="title" content={titleVal} />
      <link rel="shortcut icon" href="/favicon.png" />
      <meta name="description" content={metadescription} />
      <meta name="author" content="Good Neighbour" />
      <meta property="og:locale" content={locale} />
      {locales
        .filter((loc) => loc !== locale)
        .map((loc) => {
          return <meta key={loc} property="og:locale:alternate" content={loc} />;
        })}
      {locales
        .filter((loc) => loc !== locale)
        .map((loc) => {
          return (
            <link
              key={loc}
              rel="alternate"
              hrefLang={locale}
              href={`https://myodetox.com/${locale.toLowerCase()}${pathname}`}
            />
          );
        })}

      <link rel="alternate" href="http://myodetox.com/" hrefLang="x-default" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://myodetox.com/${locale.toLowerCase()}${pathname}`} />
      <meta property="og:title" content={titleVal} />
      <meta property="og:description" content={metadescription} />
      <meta property="article:publisher" content="https://www.facebook.com/Myodetox/" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://myodetox.com/${locale.toLowerCase()}${pathname}`} />
      <meta name="twitter:creator" content="@myodetox" />
      <meta name="twitter:creator" content="@myodetox" />
      <meta property="twitter:title" content={titleVal} />
      <meta property="twitter:description" content={metadescription} />
      <meta name="google-site-verification" content="0WIuhIeweZEkrItIzC5_8iH2Bctxa4JESQGPZIH8Of4" />
      <link rel="canonical" href="https://www.myodetox.com/" />
      <link href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css" rel="stylesheet" />
      <title>{titleVal}</title>
      {children}
    </NextHead>
  );
};

export default Head;
