import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { LanguageToggle, Logo, Button, FloatingHeader, FloatingHeroFooter } from 'components';
import { Grid, Col } from '../../components/Grid/Grid';
import { OffCanvasNav } from 'components';
import { MenuIcon } from '../Icons/MenuIcon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { WithRichTextProps } from 'utils/hooks/withRichText';
import { mq } from '../../theme';

export type FloatingFooterProps = {
  text: {
    content: WithRichTextProps;
  };
  button: {
    url: string;
    text: string;
  }[];
};

export type CTAProps = {
  url: string;
  _uid: string;
  text: string;
  target: boolean;
  component: string;
};

export type LinkProps = {
  _uid: string;
  new_tab: boolean;
  link_url: LinkUrlProps;
  component: string;
  link_text: string;
};

type LinkUrlProps = {
  id: string;
  url: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
};

type HeaderProps = {
  data: {
    _uid: string;
    cta: CTAProps[];
    links: LinkProps[];
    show_search: boolean;
    floating_footer?: FloatingFooterProps[];
    secondary_nav: LinkProps[];
    show_floating_footer: boolean;
  };
  locale: string;
  headerTheme: 'light' | 'dark';
};

type NavLinkProps = {
  active: boolean;
};

type SiteNavigationProps = {
  headerTheme: 'light' | 'dark';
};

export const Header = ({ data, locale, headerTheme }: HeaderProps) => {
  const storedRegion = sessionStorage.getItem('region');
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(0);
  const [threshold, setThreshold] = useState(0);

  const router = useRouter();
  const pathname = router?.pathname;
  const { links, cta, floating_footer, secondary_nav: secondaryNav, show_floating_footer } = data;

  const toggleMenu = () => {
    setOpen(!open);
  };

  const navRef = useRef(null);
  const floatingRef = useRef(null);
  const offCanvasRef = useRef(null);

  useEffect(() => {
    const onScroll = (e) => {
      setScrolled(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('load', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('load', onScroll);
    };
  }, [scrolled]);

  useEffect(() => {
    setThreshold(navRef.current.clientHeight);
  }, []);

  const showSection = threshold > 0 && scrolled >= threshold;
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const keyDownHandler = (e) => {
    // only execute if tab is pressed
    if (!open || (e.key !== 'Tab' && e.keyCode !== 27)) return;

    // here we query all focusable elements, customize as your own need
    const focusableNavElements = [
      ...offCanvasRef.current.querySelectorAll('a[href], button:not([disabled]), textarea, input, select'),
    ];
    const focusableFloatingElements = [
      ...floatingRef.current.querySelectorAll('a[href], button:not([disabled]), textarea, input, select'),
    ];

    const focusableModalElements = [...focusableFloatingElements, ...focusableNavElements];

    const firstElement = focusableModalElements[0];
    const lastElement = focusableModalElements[focusableModalElements.length - 1];

    if (e.key === 'Tab') {
      // if going forward by pressing tab and lastElement is active shift focus to first focusable element
      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return e.preventDefault();
      }

      // if going backward by pressing tab and firstElement is active shift focus to last focusable element
      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      }
    } else if (e.key === 'Escape') {
      setOpen(!open);
      focusableFloatingElements[focusableFloatingElements.length - 1].focus();
    }
  };

  return (
    <NavWrapper onKeyDown={keyDownHandler}>
      <SiteNavigation headerTheme={headerTheme} ref={navRef}>
        <Grid container={true}>
          <Col start={1} end={-1}>
            <NavInner>
              <LogoContainer>
                <Link href="/" locale={locale}>
                  <a>
                    <Logo />
                  </a>
                </Link>
              </LogoContainer>
              {links.length > 0 && (
                <>
                  <MainNav>
                    <ul>
                      {links.map(({ _uid, link_url, link_text }) => {
                        return (
                          <li key={_uid}>
                            <NavLink href={link_url.cached_url} active={pathname === link_url.cached_url}>
                              {link_text}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </MainNav>
                  <Hamburger
                    onClick={toggleMenu}
                    aria-controls="off-canvas-navigation"
                    aria-expanded={open ? false : true}
                  >
                    <span className="sr-only">Menu</span>
                    <MenuIcon />
                  </Hamburger>
                </>
              )}
              <NavEnd>
                {cta?.map((item, i) => {
                  return (
                    <ButtonWrap key={i}>
                      <Button
                        size="sm"
                        url={`https://${storedRegion ? storedRegion : 'myodetox'}.janeapp.com`}
                        text={item.text}
                      />
                    </ButtonWrap>
                  );
                })}
                {router && <LanguageToggle />}
              </NavEnd>
            </NavInner>
          </Col>
        </Grid>
      </SiteNavigation>
      {headerTheme === 'dark' && show_floating_footer && floating_footer && (
        <FloatingHeroFooter data={floating_footer[0]} show={showSection} />
      )}
      <FloatingHeader
        toggleMenu={toggleMenu}
        cta={cta}
        show={(threshold > 0 && scrolled >= threshold) || open}
        open={open}
        forwardedRef={floatingRef}
      />
      <OffCanvasNav
        open={open}
        toggleMenu={toggleMenu}
        links={links}
        cta={cta}
        secondaryNav={secondaryNav}
        forwardedRef={offCanvasRef}
      />
    </NavWrapper>
  );
};

const ButtonWrap = styled.div`
  display: none;

  ${mq('md')} {
    display: block;
  }
`;

const MainNav = styled.nav`
  display: none;

  ${mq('md')} {
    display: block;
  }
`;

const Hamburger = styled.button`
  display: block;
  order: -1;
  background-color: transparent;
  border: 0;
  padding: 0;
  color: inherit;
  position: relative;
  top: -2px;

  ${mq('md')} {
    display: none;
  }
`;

const NavLink = styled.a<NavLinkProps>`
  padding: 3.5rem 0;
  display: block;
  text-transform: uppercase;
  color: inherit;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;
  position: relative;
  transition: background-color 0.15s ease-in-out;

  &::before {
    content: '';
    width: 100%;
    height: 4px;
    background-color: transparent;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
  }

  &:hover {
    &::before {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }

  &::before {
    background-color: ${({ theme, active }) => (active ? theme.color.primary : 'transparent')};
  }
`;

const NavWrapper = styled.div`
  position: relative;
`;

const SiteNavigation = styled.header<SiteNavigationProps>`
  background-color: transparent;
  color: ${({ theme, headerTheme }) => (headerTheme === 'dark' ? theme.color.light : theme.color.dark)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const NavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    padding: 0 1.5rem;
  }

  svg {
    position: relative;
    top: 5px;
  }
`;

const LogoContainer = styled.div`
  max-width: 150px;
  padding: 2.5rem 0;

  a {
    color: inherit;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const NavEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
