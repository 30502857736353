import { motion } from 'framer-motion';

const transition = {
  duration: 0.5,
  ease: 'easeIn',
};

const line1Variants = {
  open: {
    x: 11,
    y: -1,
    rotate: 45,
    transition,
  },
  closed: {
    x: 0,
    y: 0,
    rotate: 0,
  },
};

const line2Variants = {
  open: {
    opacity: 0,
  },
  closed: {
    opacity: 1,
  },
};

const line3Variants = {
  open: {
    x: -10,
    y: 13,
    rotate: -45,
    transition,
  },
  closed: {
    x: 0,
    y: 0,
    rotate: 0,
  },
};

type MenuIconProps = {
  open?: boolean;
};

export const MenuIcon = ({ open }: MenuIconProps) => {
  return (
    <svg aria-hidden={true} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.line
        variants={line1Variants}
        animate={open ? 'open' : 'closed'}
        x1="5"
        y1="9.5"
        x2="25"
        y2="9.5"
        stroke="currentColor"
      />
      <motion.line
        variants={line2Variants}
        animate={open ? 'open' : 'closed'}
        x1="5"
        y1="14.5"
        x2="25"
        y2="14.5"
        stroke="currentColor"
      />
      <motion.line
        variants={line3Variants}
        animate={open ? 'open' : 'closed'}
        x1="5"
        y1="19.5"
        x2="25"
        y2="19.5"
        stroke="currentColor"
      />
    </svg>
  );
};
