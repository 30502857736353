import styled from 'styled-components';
import { Grid, Col } from 'components/Grid/Grid';
import { ELEMENT_TYPES, Text, TEXT_TYPES } from 'components/Text/Text';
import { mq } from '../../theme';
import { WithRichTextProps } from 'utils/hooks/withRichText';

type FiveImageContentBlockProps = {
  background_image: {
    filename: string;
  };
  title: {
    content: WithRichTextProps;
  };
  first_image: {
    filename: string;
    alt: string;
  };
  second_image: {
    filename: string;
    alt: string;
  };
  third_image: {
    filename: string;
    alt: string;
  };
  fourth_image: {
    filename: string;
    alt: string;
  };
  fifth_image: {
    filename: string;
    alt: string;
  };
  content: {
    _uid: string;
    icon: {
      filename: string;
      alt: string;
    };
    title: {
      content: WithRichTextProps;
    };
    description: {
      content: WithRichTextProps;
    };
  }[];
};

export const FiveImageContentBlock = ({ blok }: { blok: FiveImageContentBlockProps }) => {
  const { background_image, title, first_image, second_image, third_image, fourth_image, fifth_image, content } = blok;
  return (
    <StyledContentBlock backgroundImage={background_image.filename}>
      <Text content={title.content} element={ELEMENT_TYPES.H2} type={TEXT_TYPES.HEADING_3} />
      <StyledGridWrapper>
        <Grid columns={20}>
          <Col align="center" start="1" end="1" smStart="5" smEnd="11" rowStart="1">
            <img src={second_image.filename} alt={second_image.alt} />
          </Col>
          <Col align="end" start="7" end="11" smStart="9" smEnd="12" rowStart="1">
            <div className="third-image">
              <img src={third_image.filename} alt={third_image.alt} />
            </div>
          </Col>
          <Col align="center" start="1" end="8" smStart="1" smEnd="6" rowStart="1">
            <img src={first_image.filename} alt={first_image.alt} />
          </Col>
          <Col align="center" start="14" end="21" smStart="15" smEnd="20" rowStart="1">
            <img src={fifth_image.filename} alt={fifth_image.alt} />
          </Col>
          <Col align="start" start="11" end="15" smStart="13" smEnd="16" rowStart="1">
            <div className="fourth-image">
              <img src={fourth_image.filename} alt={fourth_image.alt} />
            </div>
          </Col>
        </Grid>
      </StyledGridWrapper>
      <Grid container={true} columns={1}>
        <StyledContent>
          {content.map(({ description, title, icon, _uid }) => (
            <StyledExperience key={_uid}>
              <IconWrapper>
                <img src={icon.filename} alt={icon.alt} />
              </IconWrapper>
              <TextWrapper>
                <Text content={title.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.HEADING_6} />
                <Text content={description.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_6} />
              </TextWrapper>
            </StyledExperience>
          ))}
        </StyledContent>
      </Grid>
    </StyledContentBlock>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(7vw + 3rem);
`;

const IconWrapper = styled.div`
  width: 5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const TextWrapper = styled.div`
  p:first-of-type {
    margin-bottom: 0.5rem;
  }
`;

const StyledExperience = styled.div`
  display: flex;
  flex-basis: 100%;
  margin: 0 0 3rem;

  ${mq('sm')} {
    flex-basis: calc(50% - 2rem);
    margin: 0 1rem 3rem;
  }
`;
const StyledGridWrapper = styled.div`
  padding: calc(7vw + 8rem) 0 6rem;

  ${mq('sm')} {
    padding: calc(7vw + 3rem) 0 7vw;
  }
`;

const StyledContentBlock = styled.section<{ backgroundImage: string }>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  padding: 20vw 0 7vw;

  h2 {
    text-align: center;
  }

  div {
    height: auto;
  }

  .third-image,
  .fourth-image {
    position: relative;
  }

  .third-image {
    top: 7rem;

    ${mq('sm')} {
      top: 3rem;
    }
  }

  .fourth-image {
    bottom: 7rem;

    ${mq('sm')} {
      bottom: 3rem;
    }
  }
`;
