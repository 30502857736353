import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';

import { Container } from '../Container/Container';
import { Button } from '../Button/Button';

const NavLink = ({ text, path }: { text: string; path: string }) => {
  const { pathname } = useRouter();

  return (
    <Link passHref href={path}>
      <NavItem $isActive={pathname === path}>{text}</NavItem>
    </Link>
  );
};

export const Nav: React.FC = () => (
  <NavWrapper>
    <Container>
      <NavFlex>
        <Logo>
          <ReactSVG src="svg/Myodetox-white.svg" />
        </Logo>
        <nav>
          <ul>
            <li>
              <NavLink text="about" path="/about" />
            </li>
          </ul>
        </nav>
        <NavElements>
          <Button text={`Book Now`} size="sm" />
          <ReactSVG src="svg/search.svg" />
        </NavElements>
      </NavFlex>
    </Container>
  </NavWrapper>
);

const NavWrapper = styled.header`
  background-image: linear-gradient(180deg, #000, rgba(0, 0, 0, 0.2));
  color: ${(props) => props.theme.color.light};
`;

const NavFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  width: 150px;

  & svg {
    width: 100%;
  }
`;

const NavElements = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const NavItem = styled.a<{ $isActive: boolean }>`
  color: ${({ theme }) => theme.color.light};
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.18em;
  text-decoration: none;
  border-top: 4px solid ${(props) => (props.$isActive ? props.theme.color.primary : 'transparent')};
  display: inline-block;
  height: 100%;

  &:hover {
    border-top-color: ${({ theme }) => theme.color.primary};
  }
`;
