import styled from 'styled-components';
import { useState } from 'react';
import { Text, TEXT_TYPES, ELEMENT_TYPES } from 'components/Text/Text';
import { Grid } from 'components/Grid/Grid';
import { Button } from 'components/Button/Button';
import { mq } from '../../theme';

const MobileInfoBlock = ({
  sessionIncludesTitle,
  sessionIncludes,
}: {
  sessionIncludesTitle: any;
  sessionIncludes: any;
}) => {
  const [showIncluded, setShowIncluded] = useState(false);
  return (
    <MobileVersion>
      <SessionIncludesButtonWrapper>
        <Text
          content={sessionIncludesTitle.content}
          element={ELEMENT_TYPES.P}
          type={TEXT_TYPES.PARAGRAPH_7}
          bold={true}
        />
        <button onClick={() => setShowIncluded(!showIncluded)}>
          <img src="/images/info-button.png" alt="" />
        </button>
      </SessionIncludesButtonWrapper>
      {showIncluded &&
        sessionIncludes.map(({ text_row_1, _uid }) => (
          <Text key={_uid} content={text_row_1.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.PARAGRAPH_7} />
        ))}
    </MobileVersion>
  );
};

export const SessionBlock = ({ blok, bookingUrl }: { blok: any; bookingUrl: string }) => {
  const { sessions } = blok;
  if (!sessions) {
    return null;
  }

  return (
    <Grid columns={1} container={true}>
      <StyledSessionBlock>
        {sessions.map(({ title, description, session_cost, session_includes, session_includes_title, _uid }, index) => {
          return (
            <StyledBlock key={_uid}>
              {index === 0 && (
                <StyledEyebrow>
                  <p>
                    Start
                    <br />
                    here
                  </p>
                </StyledEyebrow>
              )}
              <Text content={title.content} element={ELEMENT_TYPES.P} type={TEXT_TYPES.HEADING_4} />
              <Text
                className="session-block-title"
                content={description.content}
                element={ELEMENT_TYPES.P}
                type={TEXT_TYPES.PARAGRAPH_6}
              />
              <SessionCostBlock>
                {session_cost.map(({ cost, duration, _uid }) => (
                  <SessionCost className="session-cost" key={_uid}>
                    <span>
                      <sup>$</sup>
                      <Text content={cost} type={TEXT_TYPES.HEADING_4} />
                    </span>
                    <Text content={duration} type={TEXT_TYPES.PARAGRAPH_7} color="#9538CC" />
                  </SessionCost>
                ))}
              </SessionCostBlock>
              <Button url={bookingUrl} text="Book now" size="md" />
              <StyledSessionIncludesBlock>
                <MobileInfoBlock sessionIncludesTitle={session_includes_title} sessionIncludes={session_includes} />
                <DesktopVersion>
                  <Text
                    content={session_includes_title.content}
                    element={ELEMENT_TYPES.P}
                    type={TEXT_TYPES.PARAGRAPH_7}
                    bold={true}
                  />
                  {session_includes.map(({ text_row_1, _uid }) => (
                    <Text
                      key={_uid}
                      content={text_row_1.content}
                      element={ELEMENT_TYPES.P}
                      type={TEXT_TYPES.PARAGRAPH_7}
                    />
                  ))}
                </DesktopVersion>
              </StyledSessionIncludesBlock>
            </StyledBlock>
          );
        })}
      </StyledSessionBlock>
    </Grid>
  );
};

const DesktopVersion = styled.div`
  display: none;

  ${mq('sm')} {
    display: block;
  }
`;

const MobileVersion = styled.div`
  ${mq('sm')} {
    display: none;
  }
`;

const StyledEyebrow = styled.div`
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  text-align: center;
  line-height: 1.2;
  background-color: ${({ theme }) => theme.color.secondary};
  display: inline-block;
  border-radius: 50%;
  padding: 1.5rem;
  width: 10rem;
  position: absolute;
  top: -4rem;
  right: 3rem;
`;

const SessionCost = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vw;

  span {
    &:nth-of-type(2) {
      margin-left: 1rem;
    }

    sup {
      vertical-align: super;
      top: 0;

      ${mq('sm')} {
        top: -1rem;
      }
    }
    span {
      font-weight: normal;
    }
  }
`;

const StyledSessionIncludesBlock = styled.div`
  margin-top: 2rem;

  p {
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    ${mq('sm')} {
      border-bottom: ${({ theme }) => `1px solid ${theme.color.lightGray}`};
    }
  }
`;

const SessionIncludesButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    border: none;
    background: transparent;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    img {
      max-width: 65%;
    }
  }
`;

const SessionCostBlock = styled.div`
  display: flex;

  .session-cost:first-of-type {
    padding-right: 2rem;
  }

  .session-cost:not(:first-of-type) {
    border-left: ${({ theme }) => `1px solid ${theme.color.lightGray}`};
    padding: 0 2rem;
  }
`;

const StyledBlock = styled.div`
  -webkit-font-smoothing: antialiased;
  -webkit-box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  width: 100%;
  padding: 6vw;
  margin: 1rem auto;

  ${mq('sm')} {
    margin-bottom: 1rem;
    padding: 3vw 1.5vw;
    width: calc(100% / 2 - 1rem);
  }

  ${mq('md')} {
    width: calc(100% / 3);
  }

  .session-block-title {
    margin-top: 1rem;
  }

  &:nth-of-type(2) {
    ${mq('sm')} {
      margin: 0 0 1rem 1rem;
    }

    ${mq('md')} {
      margin: 1rem 2rem;
    }
  }

  a {
    margin-top: 4rem;
    width: 100%;
  }
`;

const StyledSessionBlock = styled.section`
  margin: 20vw 0;
  display: flex;
  flex-wrap: wrap;

  ${mq('sm')} {
    margin: 7vw 0;
  }

  ${mq('md')} {
    flex-wrap: unset;
  }
`;
