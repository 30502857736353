import styled from 'styled-components';
import { CTA } from '../CTA/CTA';

type CardProps = {
  title: string;
  body: string;
  CTAText: string;
  CTALink: (...args: any[]) => any | string;
};

const Box = styled.div`
  max-width: 50rem;
`;

const LockupTitle = styled.h3`
  color: ${({ theme }) => theme.color.dark};
  margin: 0;
  font-family: ${({ theme }) => theme.font.header};
  letter-spacing: -0.02em;
  font-size: 3.5rem;
  margin-bottom: 4rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 0.6rem;
    width: 4rem;
    background: ${({ theme }) => theme.color.gradient};
  }
`;

const LockupText = styled.p`
  color: ${({ theme }) => theme.color.gray};
  margin: 0;
  font-family: ${({ theme }) => theme.font.header};
  line-height: 1.2;
  font-size: 2.8rem;
  letter-spacing: -0.02em;
  margin-top: 0;
  margin-bottom: 2.3rem;
`;

export const Lockup: React.FC<CardProps> = ({ title, body, CTAText }: CardProps) => (
  <Box>
    <LockupTitle>{title}</LockupTitle>
    <LockupText>{body}</LockupText>
    {CTAText.length ? <CTA theme="dark" text={CTAText} /> : null}
  </Box>
);
