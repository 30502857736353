import { LinkedIn } from './LinkedIn';
import { Spotify } from './Spotify';
import { Instagram } from './Instagram';
import { Facebook } from './Facebook';

export { LinkedIn } from './LinkedIn';
export { Spotify } from './Spotify';
export { Instagram } from './Instagram';
export { Facebook } from './Facebook';

export const iconMap = {
  linkedin: LinkedIn,
  spotify: Spotify,
  instagram: Instagram,
  facebook: Facebook,
};
