import { TherapistBlock } from '../TherapistBlock/TherapistBlock';
import { QuoteGroup } from '../QuoteGroup/QuoteGroup';
import { BackgroundContentBlock } from '../BackgroundContentBlock/BackgroundContentBlock';
import { Reference } from '../Reference/Reference';
import { BackgroundCollageBlock } from '../BackgroundCollageBlock/BackgroundCollageBlock';
import { Hero } from '../Hero/Hero';
import { SecondaryHero } from '../SecondaryHero/SecondaryHero';
import { FadeInBackgroundContentBlock } from '../FadeInBackgroundContentBlock/FadeInBackgroundContentBlock';
import { NewsletterSubscriptionBlock } from '../NewsletterSubscriptionBlock/NewsletterSubscriptionBlock';
import { BackgroundBoxContentBlock } from '../BackgroundBoxContentBlock/BackgroundBoxContentBlock';
import { DoubleImageContentBlock } from '../DoubleImageContentBlock/DoubleImageContentBlock';
import { SpecializationBlock } from 'components/SpecializationBlock/SpecializationBlock';
import { TeamBlock } from '../TeamBlock/TeamBlock';
import { TertiaryHero } from 'components/TertiaryHero/TertiaryHero';
import { ThreeColumnContentBlock } from '../ThreeColumnContentBlock/ThreeColumnContentBlock';
import { SecondaryDoubleImageContentBlock } from '../SecondaryDoubleImageContentBlock/SecondaryDoubleImageContentBlock';
import { TwoColumnContentBlock } from 'components/TwoColumnContentBlock/TwoColumnContentBlock';
import { TextBlok } from 'components/TextBlok/TextBlok';
import { ClinicBlock } from 'components/ClinicBlock/ClinicBlock';
import { SessionBlock } from 'components/SessionBlock/SessionBlock';
import { FiveImageContentBlock } from 'components/FiveImageContentBlock/FiveImageContentBlock';
import { GradientThreeColumnBlock } from 'components/GradientThreeColumnBlock/GradientThreeColumnBlock';
import { SpecialtyBlock } from 'components/SpecialtyBlock/SpecialtyBlock';

type BlockProps = {
  blok: any;
};

const Components = {
  therapist_block: TherapistBlock,
  background_content_block: BackgroundContentBlock,
  background_box_content_block: BackgroundBoxContentBlock,
  background_collage_block: BackgroundCollageBlock,
  quote_group: QuoteGroup,
  reference: Reference,
  hero: Hero,
  secondary_hero: SecondaryHero,
  fade_in_background_content_block: FadeInBackgroundContentBlock,
  double_image_content_block: DoubleImageContentBlock,
  newsletter_subscription_block: NewsletterSubscriptionBlock,
  specialization_block: SpecializationBlock,
  team_block: TeamBlock,
  tertiary_hero: TertiaryHero,
  three_column_content_block: ThreeColumnContentBlock,
  secondary_double_image_content_block: SecondaryDoubleImageContentBlock,
  two_column_content_block: TwoColumnContentBlock,
  text_blok: TextBlok,
  clinic_block: ClinicBlock,
  session_block: SessionBlock,
  five_image_content_block: FiveImageContentBlock,
  gradint_three_column_block: GradientThreeColumnBlock,
  specialty_block: SpecialtyBlock,
};

export const DynamicBlock = ({ blok }: BlockProps) => {
  if (blok) {
    if (typeof Components[blok.component] !== 'undefined') {
      const Component = Components[blok.component];
      return <Component blok={blok} />;
    }
    return null;
  }

  return null;
};
