import { DynamicContent } from '../DynamicContent/DynamicContent';
import { Text, ELEMENT_TYPES, TEXT_TYPES } from '../Text/Text';

type ContentProps = {
  content: {
    content: any[];
  };
  element?: ELEMENT_TYPES;
  textType?: TEXT_TYPES;
};

export const InnerContents = ({ content: innerContents, element = ELEMENT_TYPES.P, textType }: ContentProps) => {
  const content = innerContents?.content?.map((inner, i) => {
    if (inner.type === 'blok') {
      return <DynamicContent key={i} blok={inner.attrs.body[0]} />;
    } else if (inner.content) {
      return <Text key={i} content={inner.content} element={element} type={textType} />;
    }
    return null;
  });

  return <>{content}</>;
};
