import { useState, useEffect } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

export enum LOCALE_MAP {
  'en-CA' = 'Canada',
  'en-US' = 'USA',
}

export const getClosestLocation = (targetLocation, locationData) => {
  if (!targetLocation || !locationData?.length) {
    return null;
  }

  const vectorDistance = (dx, dy) => {
    return Math.sqrt(dx * dx + dy * dy);
  };

  const locationDistance = (location1, location2) => {
    const dx = location1.latitude - location2.latitude,
      dy = location1.longitude - location2.longitude;

    return vectorDistance(dx, dy);
  };

  return locationData.reduce((prev, curr) => {
    const prevDistance = locationDistance(targetLocation, prev),
      currDistance = locationDistance(targetLocation, curr);
    return prevDistance < currDistance ? prev : curr;
  });
};

export const useLocale = (): { locales: string[]; locale: string; changeLocale: (loc: string) => void } => {
  const { sessionStorage } = window;
  const storedClinics = sessionStorage.getItem('clinics');
  const [userLocale, setUserLocale] = useState('en-CA');
  const { locale, locales, push, asPath } = useRouter();

  useEffect(() => {
    setUserLocale(locale);
  }, []);

  const changeLocale = (loc: string) => {
    if (locales.includes(loc)) {
      setUserLocale(loc);
      push(asPath, asPath, { locale: loc });
      // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
      Cookies.set('NEXT_LOCALE', loc);
      sessionStorage.setItem('preferredLocale', loc);

      const clinics = JSON.parse(storedClinics);
      const clinicsInCountry = clinics?.filter((clinic) => clinic.country === LOCALE_MAP[loc]);
      const closestClinic = getClosestLocation(location, clinicsInCountry);

      if (closestClinic) {
        sessionStorage.setItem('region', closestClinic.jane_base_slug);
      }
    }
  };

  return {
    locales,
    locale: userLocale,
    changeLocale,
  };
};
