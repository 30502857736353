import styled from 'styled-components';

export type ButtonProps = {
  text: string;
  onClick?: (...args: any[]) => any;
  url?: string;
  target?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'sm' | 'md';
  theme?: 'light' | 'dark';
};

export const Button: React.FC<ButtonProps> = ({
  text,
  type = 'button',
  size = 'md',
  url,
  onClick = () => null,
  target = false,
  theme = 'dark',
}: ButtonProps) => {
  if (url) {
    return (
      <ButtonEl $theme={theme} as="a" href={url} $size={size} target={target ? '_blank' : ''}>
        {text}
      </ButtonEl>
    );
  }
  return (
    <ButtonEl $theme={theme} as="button" $size={size} type={type} onClick={onClick}>
      {text}
    </ButtonEl>
  );
};

Button.displayName = 'Button';

const handleButtonSize = (size: string) => {
  switch (size) {
    case 'sm':
      return `
        padding: 1.1rem 2rem;
        font-size: 1.2rem;
      `;
    case 'md':
      return `
        padding: 1.5rem 2.5rem;
        font-size: 1.6rem;
      `;
    default:
      return `
        padding: 1.5rem 2.5rem;
        font-size: 1.6rem;
      `;
  }
};

const ButtonEl = styled.div<{ $size: string; $theme: string }>`
  display: inline-block;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.radius.button};
  border: none;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: ${({ theme, $theme }) => ($theme === 'dark' ? theme.color.light : theme.color.primary)};
  background-color: ${({ theme, $theme }) => ($theme === 'dark' ? theme.color.primary : theme.color.secondary)};
  background-image: ${({ theme, $theme }) => ($theme === 'dark' ? theme.color.gradient : 'none')};
  text-align: center;
  transition: 0.15s background-color ease, 0.15s color ease;
  outline: 0;
  font-family: ${({ theme }) => theme.font.body};
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: ${({ theme, $theme }) => ($theme === 'dark' ? theme.color.primary : theme.color.secondary)};
    background: ${({ theme, $theme }) => ($theme === 'dark' ? theme.color.secondary : theme.color.primary)};
  }

  &:focus {
    outline: 2px solid currentColor;
  }

  ${(props) => handleButtonSize(props.$size)}
`;
