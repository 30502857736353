import styled from 'styled-components';
import { mq } from '../../theme';
import SbEditable from 'storyblok-react';
import { InnerContents } from '../InnerContents/InnerContents';
import { ELEMENT_TYPES, TEXT_TYPES } from '../Text/Text';

type BlokProps = {
  blok: {
    _uid: string;
    component: string;
    row_1_images?: any[];
    row_2_images?: any[];
    row_3_images?: any[];
    content: {
      content: any[];
    };
  };
};

export const BackgroundCollageBlock: React.FC<BlokProps> = ({ blok }: BlokProps) => {
  const { row_1_images: row1, row_2_images: row2, row_3_images: row3, content } = blok;

  return (
    <SbEditable content={blok}>
      <Container>
        {row1.length > 0 && (
          <ImageRow $align="center" $justify="space-around">
            {row1.map((image) => {
              return (
                <ImgHolder key={image._uid} $width={25} $align={image.align}>
                  <Img $width={65} src={image.image.filename} alt="" />
                </ImgHolder>
              );
            })}
          </ImageRow>
        )}
        {row2.length > 0 && (
          <ImageRow $align="center" $justify="space-beteween">
            <ImgHolderMiddle key={row2[0]._uid} $width={20} $align={row2[0].align}>
              <Img key={row2[0]._uid} $width={75} src={row2[0].image.filename} alt="" />
            </ImgHolderMiddle>
            <TextContainer>
              <InnerContents element={ELEMENT_TYPES.H2} textType={TEXT_TYPES.HEADING_4} content={content} />
            </TextContainer>
            <ImgHolderMiddle key={row2[1]._uid} $width={20} $align={row2[1].align}>
              <Img key={row2[1]._uid} $width={75} src={row2[1].image.filename} alt="" />
            </ImgHolderMiddle>
          </ImageRow>
        )}
        {row3.length > 0 && (
          <ImageRow $align="center" $justify="space-around">
            {row3.map((image) => {
              return (
                <ImgHolder key={image._uid} $width={25} $align={image.align}>
                  <Img $width={65} src={image.image.filename} alt="" />
                </ImgHolder>
              );
            })}
          </ImageRow>
        )}
      </Container>
    </SbEditable>
  );
};

const TextContainer = styled.div`
  text-align: center;
  line-height: 1.1;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;

  h2 {
    color: white;
    max-width: 80%;
    margin: 0 auto 3vmax;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ${mq('md')} {
    max-width: 60%;
  }
`;

const Img = styled.img<{ $width: number }>`
  max-width: ${({ $width }) => $width}%;
  max-height: 100%;
  opacity: 0.25;

  ${mq('md')} {
    opacity: 1;
  }
`;

const ImgHolder = styled.div<{ $align: string; $width: number }>`
  max-width: ${({ $width }) => $width}%;
  max-height: 100%;
  display: flex;
  justify-content: ${({ $align }) => $align};
  align-items: center;
`;

const ImgHolderMiddle = styled(ImgHolder)<{ $align: string; $width: number }>`
  display: none;
  overflow: hidden;
  height: 100%;

  ${mq('md')} {
    display: flex;
  }

  &:first-child {
    img {
      transform: scale(1.5) translateX(-15%);
    }
  }

  &:last-child {
    img {
      transform: scale(1.5) translateX(15%);
    }
  }
`;

const ImageRow = styled.div<{ $align: string; $justify: string }>`
  display: flex;
  align-items: ${({ $align }) => $align};
  justify-content: space-between;
`;

const Container = styled.div`
  display: grid;
  grid-row-gap: 3vmax;
  background-color: ${({ theme }) => theme.color.primary};
  background-image: ${({ theme }) => theme.color.gradient};
  background-attachment: fixed;
  color: ${({ theme }) => theme.color.light};
`;
