import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Link from 'next/link';

export type Theme = 'light' | 'dark';
export type ButtonProps = {
  text?: string;
  onClick?: (...args: any[]) => any;
  theme?: Theme;
  url?: string;
  justify?: string;
};

const CTABox = styled.div<{ $theme: string; $justify: string }>`
  color: ${({ theme, $theme }) => ($theme === 'light' ? theme.color.light : theme.color.primary)};
  padding: 0;
  font-weight: bold;
  font-size: 1.6rem;
  background: none;
  border: none;
  appearance: none;
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  align-items: center;
  text-decoration: none;
`;

const CTAArrow = styled.div<{ $theme: string }>`
  margin-left: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid ${({ theme, $theme }) => ($theme === 'light' ? theme.color.light : theme.color.primary)};
  border-radius: 50%;
  text-align: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & svg {
    height: 1.5rem;
    height: 1.5rem;
    transform: rotate(270deg);
    fill: ${({ theme, $theme }) => ($theme === 'light' ? theme.color.light : theme.color.primary)};
  }
`;

export const CTA: React.FC<ButtonProps> = ({ text, onClick, url, theme = 'light', justify }: ButtonProps) => {
  if (url) {
    return (
      <Link href={url} passHref>
        <CTABox as="a" $theme={theme} $justify={justify}>
          {text}
          <CTAArrow $theme={theme}>
            <ReactSVG src="svg/arrow.svg" />
          </CTAArrow>
        </CTABox>
      </Link>
    );
  }
  return (
    <CTABox as="button" type="button" onClick={onClick} $theme={theme} $justify={justify}>
      {text}
      <CTAArrow $theme={theme}>
        <ReactSVG src="svg/arrow.svg" />
      </CTAArrow>
    </CTABox>
  );
};
